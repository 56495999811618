// ========================================
// Why Kesennuma ?
// ========================================
.c-icon--weather {
  position: relative;

  &::after {
    content: '';
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
    background: url(../images/common/c-icon-weather.svg) no-repeat center center;
    background-size: 57px auto;
    background-color: #fff;
    position: absolute;
    left: -50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include media-breakpoint-down(sm) {
  .c-icon--weather {
    position: relative;
    padding-left: 50px;
    padding-bottom: 1.5rem !important;

    &::after {
      content: '';
      width: 29px;
      height: 29px;
      display: block;
      border-radius: 50%;
      background: url(../images/common/c-icon-weather.svg) no-repeat center center;
      background-size: 29px auto;
      background-color: #fff;
      position: absolute;
      left: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
