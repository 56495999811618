.maplat-iframe-wrapper {
  // position: absolute;
  // top: 200px;
  // left: 0;
  // right: 0;
  // margin-top: 100px;
  width: 100%;
  height: 100vh;
  border: 0;
}
