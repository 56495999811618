// ========================================
// Highlights
// ========================================

.highlights {
  color: colors("white");
  position: relative;

  .btn.share {
    width: 38px;
    height: 38px;
  }

  //Wordpress記事入力用支援CSS
  &__inner {
    color: colors("white");

    h1, h2, h3, h4, h5, h6 {
      font-family: 'Frank Ruhl Libre';
      font-weight: 700 !important;
    }

    .aligncenter {
      text-align: center;
    }

    .alignright {
      text-align: right;
    }

    .alignleft {
      text-align: left;
    }

    p{
      @include font-size($lead-font-size);
    }

    hr.wp-block-separator{
      border-top: 3px solid color("white");
      margin: 7rem 0;
    }

    pre {
      color: colors("white");
      max-height: auto;
      margin: 0;
      padding: 3rem;

      @include font-size($lead-font-size);
    }

    _:-ms-fullscreen, :root img {
      width: auto;
      height: auto;
      flex-shrink: 0;
    }

    img {
      height: auto;
    }
  }

  &__swiper-img-wrap {
    height: 270px;
  }

  &__swiper-inner-img-wrap {
    height: 217px;
  }

  &__author-img {
    width: 90px;
    height: 90px;
    box-sizing: border-box;
    margin: 0 20px 0 10px;
  }

  @include media-breakpoint-down(sm) {
    &__author-img {
      width: 70px;
      height: 70px;
      box-sizing: border-box;
      margin: 0 10px 0 15px;

      .border--width-bold {
        border-width: 3px !important;
      }
    }
  }

  &__author-name {
    width: calc(100% - 140px);
  } // アイコン大きさ+左右のマージン+左側に設定したマージン

  @include media-breakpoint-down(sm) {
    &__author-name {
      width: calc(100% - 105px);
    } // アイコン大きさ+左右のマージン+左側に設定したマージン
  }
}


.the-highlights-type-icon {
  width: 70px;
  height: 70px;
}