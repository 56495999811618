// ---------------------------------
// Modaal  Css Override
// https://github.com/humaan/Modaal
// ヘッダーで利用するモーダルのみに適応（.modaal-menu, .modaal-search）
// ---------------------------------

// グローバルメニュー、複数のモーダルウィンドウを許可しない
// ------------------------------
.modaal-noscroll {
  .js-modaal {
    pointer-events: none;

    &.js-modaal-active {
      pointer-events: visible;
    }
  }
}

// グローバル
// ------------------------------
.modaal-wrapper {
  &.modaal-menu,
  &.modaal-search {
    // モーダルウィンドウ表示時：ズレ調整
    height: calc(100% - 80px);
    overflow: hidden;
    top: auto;
    bottom: 0;

    // メニューと検索時のactive時にheaderを隠さない
    + .modaal-overlay {
      z-index: 998;
    }

    .modaal-container {
      background: transparent;
    }
  }

  &.modaal-video {
    .modaal-container {
      background: transparent;
      box-shadow: none;
    }
    .modaal-content-container {
      padding: 0;
    }
  }
}

// モーダルウィンドウ表示時：ズレ調整
// ------------------------------
//.modaal-wrapper {
//  &.modaal-menu,
//  &.modaal-search {
//    height: calc(100% - 80px);
//    overflow: hidden;
//    top: unset;
//    bottom: 0;
//
//    + .modaal-overlay {
//      z-index: 998;
//    }
//    // メニューと検索時のactive時にheaderを隠さない
//  }
//}

// モーダルウィンドウ表示時：ズレ調整（モバイル）
// ------------------------------
@include media-breakpoint-down(sm) {
  .modaal-wrapper {
    &.modaal-menu,
    &.modaal-search {
      height: calc(100% - 50px);
    }
  }
}

// モーダルウィンドウ：ボタン制御
// ------------------------------
@keyframes modaal-fade-in {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.js-modaal-close {
  display: none;
  opacity: 0;

  @include transition();
}

.js-modaal-active {
  // モーダルウィンドウ：クローズボタン 元ファイルは parts-primitive/_icon 参照
  .c-icon--close {
    width: 20px;
    height: 20px;
    border: none;
  }

  .js-modaal-menu,
  .js-modaal-search {
    display: none;
    opacity: 0;
  }

  .js-modaal-close {
    display: block;
    opacity: 1;
    // todo:グローバルメニュー、アニメーション速度
    animation-duration: 0.5s;
    animation-name: modaal-fade-in;
  }
}


// グローバルメニュー（モバイル）
// ------------------------------
.modaal-menu {
  // SNSアイコン 元ファイルは parts-primitive/_icon 参照
  .c-icon--instagram {
    width: 60px;
    height: 60px;
    display: inline-block;
    background-size: 30px auto;
    background-position: center center;
    background-color: $white;
  }

  // SNSアイコン 元ファイルは parts-primitive/_icon 参照
  .c-icon--twitter {
    width: 60px;
    height: 60px;
    display: inline-block;
    background-size: 33.5px auto;
    background-position: center center;
    background-color: $white;
  }
}


// グローバル検索
// ------------------------------
.modaal-search {
  .contents-search {
    max-width: 800px;
    border: 4px solid white;
  }
}

@include media-breakpoint-down(sm) {
  .modaal-search {
    .modaal-content-container {
      padding: 15px;
    }
  }

  .contents-search {
    max-width: 100%;
  }
}


// グローバル：closeボタン
.modaal-wrapper .modaal-close {
  background: transparent;
  opacity: 1;

  @include transition();

  &:focus::before,
  &:focus::after,
  &:hover::before,
  &:hover::after {
    background: colors("white");
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }
}

// Close button override
.modaal-close {
  position: absolute;
  right: 0;
  top: -50px;
}
