// ========================================
// footer
// ========================================
.Footer {
  background-color: colors("black");
  color: colors("white");
  padding: 6rem 0;

  // SNSアイコン 元ファイルは parts-primitive/_icon 参照
  .c-icon--instagram {
    width: 50px;
    height: 50px;
    display: inline-block;
    background-size: 25px auto;
    background-position: center center;
    background-color: $white;
  }

  // SNSアイコン 元ファイルは parts-primitive/_icon 参照
  .c-icon--twitter {
    width: 50px;
    height: 50px;
    display: inline-block;
    background-size: 28.5px auto;
    background-position: center center;
    background-color: $white;
  }
}

@include media-breakpoint-down(sm) {
  .Footer {
    padding: 3rem 0;

    // SNSアイコン 元ファイルは parts-primitive/_icon 参照
    .c-icon--instagram {
      width: 40px;
      height: 40px;
      display: inline-block;
      background-size: 20px auto;
      background-position: center center;
      background-color: $white;
    }

    // SNSアイコン 元ファイルは parts-primitive/_icon 参照
    .c-icon--twitter {
      width: 40px;
      height: 40px;
      display: inline-block;
      background-size: 23.5px auto;
      background-position: center center;
      background-color: $white;
    }
  }
}

// ---------------------------------
// Project
// ---------------------------------
.p-footer {
  &-title {
    max-width: 1080px;
    min-width: 1080px;
  }

  &-container {
    max-width: 1080px;
    min-width: 1080px;
  }
}

@include media-breakpoint-down(sm) {
  .p-footer {
    &-title {
      max-width: 100%;
      min-width: 100%;
    }

    &-container {
      max-width: 100%;
      min-width: 100%;
    }
  }
}


// ---------------------------------
// Component
// ---------------------------------
// fontSize
.c-footer-font-size {
  font-size: $font-size-base;

  @include tracking(35);
}  // 17px

.c-footer-font-size-sm {
  font-size: $font-size-base * 0.882352941176471;
  line-height: 2;

  @include tracking(35);
}  // 15px

@include media-breakpoint-down(sm) {
  .c-footer-font-size {
    font-size: $font-size-base-sp *  1.153846153846154;
  }
}  // 15px

// ---------------------------------
// .footer__lists
// ---------------------------------

.lang-id .footer__lists {
  @include media-breakpoint-up(md) {
    width: 200px;
  }
}

.lang-id .footer__list {
  @include media-breakpoint-up(md) {
    font-size: 1.3rem;
  }
}
