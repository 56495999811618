// ========================================
// variables.scss
// ========================================

// ---------------------------------
// Fonts
// ---------------------------------
// [note] $font-family-base = $font-family-sans-serif
$font-family-sans-serif: 'Poppins', Helvetica Neue, Helvetica, Arial, sans-serif, Yu Gothic;
$font-family-condensed-sans-serif: Helvetica Neue, Helvetica, Arial, sans-serif, Yu Gothic;
$font-family-serif: Times, Georgia, serif;
$font-family-monospace: monospace;

$font-family-base: $font-family-sans-serif;
$font-family-base-cond: $font-family-condensed-sans-serif;
$font-family-display: $font-family-sans-serif;
$headings-font-family: $font-family-sans-serif;


// ---------------------------------
// Font
// ---------------------------------
$font-size-base: 1.7rem;
$font-size-base-sp: 1.3rem;
$font-weight-base: 400;
$font-weight-bold: 600;
$headings-font-weight: 600;
// $font-size-xl: ($font-size-base * 1.5); // 24px
// $font-size-lg: ($font-size-base * 1.25);  // 20px
// $font-size-md: ($font-size-base * 1.125); // 18px
// $font-size-sm: ($font-size-base * 0.875); // 14px
// $font-size-xs: ($font-size-base) * 0.75;  // 12px
$font-size-xl: ($font-size-base * 3.529411764705882); // 60px
$font-size-lg: ($font-size-base * 2.352941176470588); // 40px
$font-size-md: ($font-size-base * 1.529411764705882); // 26px
$font-size-sm: ($font-size-base * 1.176470588235294); // 20px
$font-size-xs: ($font-size-base) * 0.882352941176471; // 15px
$lead-font-size: $font-size-base; // 17px
// th
$font-size-xl-th: ($font-size-base * 3.823529411764706); // 65px
$font-size-lg-th: ($font-size-base * 2.352941176470588); // 45px
$font-size-md-th: ($font-size-base * 1.647058823529412); // 28px
$font-size-sm-th: ($font-size-base * 1.294117647058824); // 22px
$font-size-xs-th: ($font-size-base) * 1.117647058823529; // 19px
$lead-font-size-th: ($font-size-base) * 0.941176470588235; // 16px
// tw
$font-size-xl-tw: ($font-size-base * 3.235294117647059); // 55px
$font-size-lg-tw: ($font-size-base * 2.058823529411765); // 35px
$font-size-md-tw: ($font-size-base * 1.411764705882353); // 24px
$font-size-sm-tw: ($font-size-base * 1.058823529411765); // 18px
$font-size-xs-tw: ($font-size-base) * 0.941176470588235; // 16px
$lead-font-size-tw: ($font-size-base) * 0.823529411764706; // 14px
// for Mobile
$font-size-xl-sp: ($font-size-base-sp * 3.076923076923077); // 40px
$font-size-lg-sp: ($font-size-base-sp * 2.307692307692308); // 30px
$font-size-md-sp: ($font-size-base-sp * 1.538461538461538); // 20px
$font-size-sm-sp: ($font-size-base-sp * 1.153846153846154); // 15px
$font-size-xs-sp: ($font-size-base-sp) * 0.884615384615385; // 11.5px
$lead-font-size-sp: $font-size-base-sp; // 13px


// ---------------------------------
// Heading Font
// ---------------------------------
//$h1-font-size: $font-size-base * 3; // 48px
//$h2-font-size: $font-size-base * 2; // 32px
//$h3-font-size: $font-size-base * 1.75; // 28px
//$h4-font-size: $font-size-base * 1.5; // 24px
//$h5-font-size: $font-size-base * 1.25; // 20px
//$h6-font-size: $font-size-base; // 16px
$h1-font-size: $font-size-base * 3.529411764705882; // 60px
$h2-font-size: $font-size-base * 2.352941176470588; // 40px
$h3-font-size: $font-size-base * 1.529411764705882; // 26px
$h4-font-size: $font-size-base * 1.176470588235294; // 20px
$h5-font-size: $font-size-base; // 17px
$h6-font-size: $font-size-base * 0.882352941176471; // 15px
// for Mobile
$h1-font-size-sp: $font-size-base-sp * 3.076923076923077; // 40px
$h2-font-size-sp: $font-size-base-sp * 2.307692307692308; // 30px
$h3-font-size-sp: $font-size-base-sp * 1.538461538461538; // 20px
$h4-font-size-sp: $font-size-base-sp * 1.153846153846154; // 15px
$h5-font-size-sp: $font-size-base-sp; // 13px
$h6-font-size-sp: $font-size-base-sp * 0.884615384615385; // 11.5px


// ---------------------------------
// Color System: Define Function
// ---------------------------------
$colors: ();

@function colors($key) {
  @return map-get($colors, $key);
}

$theme-colors: ();

@function theme-colors($key) {
  @return map-get($theme-colors, $key);
}

@function get-scale-color($color-info, $scaleLength, $percent) {
  $r: map-get($color-info, r);
  $g: map-get($color-info, g);
  $b: map-get($color-info, b);
  $ratio: $scaleLength - ($percent / 10);
  $result-r: $r + ((255 - $r) / $scaleLength) * $ratio;
  $result-g: $g + ((255 - $g) / $scaleLength) * $ratio;
  $result-b: $b + ((255 - $b) / $scaleLength) * $ratio;

  @return rgb($result-r, $result-g, $result-b);
}

// ---------------------------------
// Color System: Define Colors
// ---------------------------------
$white: #fff;
$black: #1e1e1e;
$blue : #002347;
$yellow : #eae43f;
$color-white: (hex: #fff, r: 255, g: 255, b: 255);
$color-black: (hex: #1e1e1e, r: 30, g: 30, b: 30);
$color-gray: (hex: #656565, r: 101, g: 101, b: 101);
$color-living-coral: (hex: #ff6f61, r: 255, g: 111, b: 97);
$color-aqua-sky: (hex: #7ac5c5, r: 122, g: 197, b: 197);
$color-mimosa: (hex: #f0bf59, r: 240, g: 191, b: 89);
$color-turtle-green: (hex: #81894e, r: 129, g: 137, b: 78);
$color-ultra-violet: (hex: #5f4b8b, r: 95, g: 75, b: 139);
$color-red: (hex: #f00, r: 255, g: 0, b: 0);
//$color-blue: (hex: #3256a7, r: 50, g: 86, b: 167);
$color-blue: (hex: #002347, r: 0, g: 35, b: 71);
$color-dark-cyan: (hex: #008b8b, r: 0, g: 139, b: 139);
$color-yellow: (hex: #eae43f, r: 234, g: 228, b: 63);
$color-blue-access: #003d7a;

$colors: map-merge(
  (
    "white": map-get($color-white, hex),
    "yellow": map-get($color-yellow, hex),
    "black": map-get($color-black, hex),
    "black-900": get-scale-color($color-black, 10, 90),
    "black-800": get-scale-color($color-black, 10, 80),
    "black-700": get-scale-color($color-black, 10, 70),
    "black-600": get-scale-color($color-black, 10, 60),
    "black-500": get-scale-color($color-black, 10, 50),
    "black-400": get-scale-color($color-black, 10, 40),
    "black-300": get-scale-color($color-black, 10, 30),
    "black-200": get-scale-color($color-black, 10, 20),
    "black-100": get-scale-color($color-black, 10, 10),
    "gray": map-get($color-gray, hex),
    "gray-900": get-scale-color($color-gray, 10, 90),
    "gray-800": get-scale-color($color-gray, 10, 80),
    "gray-700": get-scale-color($color-gray, 10, 70),
    "gray-600": get-scale-color($color-gray, 10, 60),
    "gray-500": get-scale-color($color-gray, 10, 50),
    "gray-400": get-scale-color($color-gray, 10, 40),
    "gray-300": get-scale-color($color-gray, 10, 30),
    "gray-200": get-scale-color($color-gray, 10, 20),
    "gray-100": get-scale-color($color-gray, 10, 10),
    "living-coral": map-get($color-living-coral, hex),
    "living-coral-900": get-scale-color($color-living-coral, 10, 90),
    "living-coral-800": get-scale-color($color-living-coral, 10, 80),
    "living-coral-700": get-scale-color($color-living-coral, 10, 70),
    "living-coral-600": get-scale-color($color-living-coral, 10, 60),
    "living-coral-500": get-scale-color($color-living-coral, 10, 50),
    "living-coral-400": get-scale-color($color-living-coral, 10, 40),
    "living-coral-300": get-scale-color($color-living-coral, 10, 30),
    "living-coral-200": get-scale-color($color-living-coral, 10, 20),
    "living-coral-100": get-scale-color($color-living-coral, 10, 10),
    "aqua-sky": map-get($color-aqua-sky, hex),
    "aqua-sky-900": get-scale-color($color-aqua-sky, 10, 90),
    "aqua-sky-800": get-scale-color($color-aqua-sky, 10, 80),
    "aqua-sky-700": get-scale-color($color-aqua-sky, 10, 70),
    "aqua-sky-600": get-scale-color($color-aqua-sky, 10, 60),
    "aqua-sky-500": get-scale-color($color-aqua-sky, 10, 50),
    "aqua-sky-400": get-scale-color($color-aqua-sky, 10, 40),
    "aqua-sky-300": get-scale-color($color-aqua-sky, 10, 30),
    "aqua-sky-200": get-scale-color($color-aqua-sky, 10, 20),
    "aqua-sky-100": get-scale-color($color-aqua-sky, 10, 10),
    "mimosa": map-get($color-mimosa, hex),
    "mimosa-900": get-scale-color($color-mimosa, 10, 90),
    "mimosa-800": get-scale-color($color-mimosa, 10, 80),
    "mimosa-700": get-scale-color($color-mimosa, 10, 70),
    "mimosa-600": get-scale-color($color-mimosa, 10, 60),
    "mimosa-500": get-scale-color($color-mimosa, 10, 50),
    "mimosa-400": get-scale-color($color-mimosa, 10, 40),
    "mimosa-300": get-scale-color($color-mimosa, 10, 30),
    "mimosa-200": get-scale-color($color-mimosa, 10, 20),
    "mimosa-100": get-scale-color($color-mimosa, 10, 10),
    "turtle-green": map-get($color-turtle-green, hex),
    "turtle-green-900": get-scale-color($color-turtle-green, 10, 90),
    "turtle-green-800": get-scale-color($color-turtle-green, 10, 80),
    "turtle-green-700": get-scale-color($color-turtle-green, 10, 70),
    "turtle-green-600": get-scale-color($color-turtle-green, 10, 60),
    "turtle-green-500": get-scale-color($color-turtle-green, 10, 50),
    "turtle-green-400": get-scale-color($color-turtle-green, 10, 40),
    "turtle-green-300": get-scale-color($color-turtle-green, 10, 30),
    "turtle-green-200": get-scale-color($color-turtle-green, 10, 20),
    "turtle-green-100": get-scale-color($color-turtle-green, 10, 10),
    "ultra-violet": map-get($color-ultra-violet, hex),
    "ultra-violet-900": get-scale-color($color-ultra-violet, 10, 90),
    "ultra-violet-800": get-scale-color($color-ultra-violet, 10, 80),
    "ultra-violet-700": get-scale-color($color-ultra-violet, 10, 70),
    "ultra-violet-600": get-scale-color($color-ultra-violet, 10, 60),
    "ultra-violet-500": get-scale-color($color-ultra-violet, 10, 50),
    "ultra-violet-400": get-scale-color($color-ultra-violet, 10, 40),
    "ultra-violet-300": get-scale-color($color-ultra-violet, 10, 30),
    "ultra-violet-200": get-scale-color($color-ultra-violet, 10, 20),
    "ultra-violet-100": get-scale-color($color-ultra-violet, 10, 10),
    "red": map-get($color-red, hex),
    "red-900": get-scale-color($color-red, 10, 90),
    "red-800": get-scale-color($color-red, 10, 80),
    "red-700": get-scale-color($color-red, 10, 70),
    "red-600": get-scale-color($color-red, 10, 60),
    "red-500": get-scale-color($color-red, 10, 50),
    "red-400": get-scale-color($color-red, 10, 40),
    "red-300": get-scale-color($color-red, 10, 30),
    "red-200": get-scale-color($color-red, 10, 20),
    "red-100": get-scale-color($color-red, 10, 10),
    "blue": map-get($color-blue, hex),
    "blue-900": get-scale-color($color-blue, 10, 90),
    "blue-800": get-scale-color($color-blue, 10, 80),
    "blue-700": get-scale-color($color-blue, 10, 70),
    "blue-600": get-scale-color($color-blue, 10, 60),
    "blue-500": get-scale-color($color-blue, 10, 50),
    "blue-400": get-scale-color($color-blue, 10, 40),
    "blue-300": get-scale-color($color-blue, 10, 30),
    "blue-200": get-scale-color($color-blue, 10, 20),
    "blue-100": get-scale-color($color-blue, 10, 10),
    "dark-cyan": map-get($color-dark-cyan, hex),
    "dark-cyan-900": get-scale-color($color-dark-cyan, 10, 90),
    "dark-cyan-800": get-scale-color($color-dark-cyan, 10, 80),
    "dark-cyan-700": get-scale-color($color-dark-cyan, 10, 70),
    "dark-cyan-600": get-scale-color($color-dark-cyan, 10, 60),
    "dark-cyan-500": get-scale-color($color-dark-cyan, 10, 50),
    "dark-cyan-400": get-scale-color($color-dark-cyan, 10, 40),
    "dark-cyan-300": get-scale-color($color-dark-cyan, 10, 30),
    "dark-cyan-200": get-scale-color($color-dark-cyan, 10, 20),
    "dark-cyan-100": get-scale-color($color-dark-cyan, 10, 10),
    "alpha-black-10": rgba(map-get($color-black, hex), 0.1),
    "alpha-black-20": rgba(map-get($color-black, hex), 0.2),
    "alpha-black-30": rgba(map-get($color-black, hex), 0.3),
    "alpha-black-40": rgba(map-get($color-black, hex), 0.4),
    "alpha-black-50": rgba(map-get($color-black, hex), 0.5),
    "alpha-black-60": rgba(map-get($color-black, hex), 0.6),
    "alpha-black-70": rgba(map-get($color-black, hex), 0.7),
    "alpha-black-80": rgba(map-get($color-black, hex), 0.8),
    "alpha-black-90": rgba(map-get($color-black, hex), 0.9),
    "alpha-white-10": rgba(map-get($color-white, hex), 0.1),
    "alpha-white-20": rgba(map-get($color-white, hex), 0.2),
    "alpha-white-30": rgba(map-get($color-white, hex), 0.3),
    "alpha-white-40": rgba(map-get($color-white, hex), 0.4),
    "alpha-white-50": rgba(map-get($color-white, hex), 0.5),
    "alpha-white-60": rgba(map-get($color-white, hex), 0.6),
    "alpha-white-70": rgba(map-get($color-white, hex), 0.7),
    "alpha-white-80": rgba(map-get($color-white, hex), 0.8),
    "alpha-white-90": rgba(map-get($color-white, hex), 0.9),
    "alpha-blue-10": rgba(map-get($color-blue, hex), 0.1),
  ),
  $colors
);


// ---------------------------------
// Color System: Define Theme Colors
// ---------------------------------
// [note]
// theme-colors() is special function
// You can use auto utilitiy classes like this.
// .text-primary, .bg-secondary

// Bootstrap
// ------------------------------
$primary: colors("blue");
$secondary: colors("turtle-green");
$success: colors("mimosa");
$info: colors("ultra-violet");
$warning: colors("mimosa");
$danger: colors("red-900");
$light: colors("gray-200");
$dark: colors("black-800");
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);


// Accent Theme
// ------------------------------
$color-accent-1: colors("gray-200");
$color-accent-2: colors("aqua-sky-200");
$color-accent-3: colors("living-coral-200");
$color-accent-4: colors("turtle-green-200");
$color-accent-5: colors("ultra-violet-200");
$color-accent-6: colors("mimosa-200");
$color-accent-7: colors("mimosa-200");
$color-accent-8: colors("mimosa-200");
$theme-colors: map-merge(
  (
    "accent-1": $color-accent-1,
    "accent-2": $color-accent-2,
    "accent-3": $color-accent-3,
    "accent-4": $color-accent-4,
    "accent-5": $color-accent-5,
    "accent-6": $color-accent-6,
    "accent-7": $color-accent-7,
    "accent-8": $color-accent-8
  ),
  $theme-colors
);

// Web Site Theme
// ------------------------------
$color-theme-1: colors("gray");
$color-theme-2: colors("gray");
$color-theme-3: colors("gray");
$color-theme-4: colors("gray");
$color-theme-1-weak: colors("gray-200");
$color-theme-2-weak: colors("gray-200");
$color-theme-3-weak: colors("gray-200");
$color-theme-4-weak: colors("gray-200");
$theme-colors: map-merge(
  (
    "theme-1": $color-theme-1,
    "theme-2": $color-theme-2,
    "theme-3": $color-theme-3,
    "theme-4": $color-theme-4,
    "theme-1-weak": $color-theme-1-weak,
    "theme-2-weak": $color-theme-2-weak,
    "theme-3-weak": $color-theme-3-weak,
    "theme-4-weak": $color-theme-4-weak
  ),
  $theme-colors
);


// Brand theme
// ------------------------------
$color-dropbox: #1f8ce6;
$color-facebook: #1877f2;
$color-feedly: #6cc655;
$color-flicker: #ff0084;
$color-google-plus: #dd4b39;
$color-instagram: #e1306c;
$color-line: #1dcd00;
$color-linkedin: #cd201f;
$color-pinterest: #cb2027;
$color-tumblr: #2c4762;
$color-twitter: #55acee;
$color-youtube: #cd201f;
$color-sinaweibo: #df2029;
$color-whatsapp: #25D366;
$theme-colors: map-merge(
  (
    "dropbox": $color-dropbox,
    "facebook": $color-facebook,
    "feedly": $color-feedly,
    "flicker": $color-flicker,
    "google-plus": $color-google-plus,
    "instagram": $color-instagram,
    "line": $color-line,
    "linkedin": $color-linkedin,
    "pinterest": $color-pinterest,
    "tumblr": $color-tumblr,
    "twitter": $color-twitter,
    "youtube": $color-youtube,
    "sinaweibo": $color-sinaweibo,
    "whatsapp": $color-whatsapp
  ),
  $theme-colors
);


// ---------------------------------
// Element Style
// ---------------------------------

// Element - Base
// ---------------------------------
//$body-bg: colors("#3256a7");
$body-bg: $blue;
$body-color: colors("black-900");
$body-rvs-bg: $body-color;
$body-rvs-color: $body-bg;
$link-color: colors("white");
$link-hover-color: darken($link-color, 15%);
$link-decoration: underline;
$link-hover-decoration: underline;
$color-border-cmn: colors("gray-400");

// Element - header
// ---------------------------------
$header-bg-color: colors("white");
$header-color: colors("white");
$header-link-color: colors("white");
$header-link-nvr-color: colors("white");
$header-height: 70px;

// Element - footer
// ---------------------------------
$footer-bg-color: colors("black");
$footer-color: colors("white");
$footer-link-color: colors("white");
$footer-link-nvr-color: colors("white");

// Element - breadcrumb
// ---------------------------------
$breadcrumb-wrap-bg-color: colors("gray-400");
$breadcrumb-bg: colors("gray-400");
$breadcrumb-divider: ">";


// ---------------------------------
// Grid columns / Spacers
// ---------------------------------
$spacer: 1rem; // Default: 1rem
$grid-gutter-width: 30px; // Default: 30px
$grid-gutter-half-width: $grid-gutter-width / 2;
$spacer-g: $grid-gutter-width;// Alias
$spacer-hg: $grid-gutter-half-width;// Alias
$spacer-cmn: $grid-gutter-half-width;// Alias

$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 2),
    5: ($spacer * 3),
    6: ($spacer * 4),
    7: ($spacer * 5.5),
    8: ($spacer * 7),
    9: ($spacer * 9),
    1px: 1px,
    5px: 5px,
    10px: 10px,
    15px: 15px,
    20px: 20px,
    25px: 25px,
    30px: 30px,
    35px: 35px,
    g: $grid-gutter-width,
    hg: $grid-gutter-half-width,
    cmn: $spacer-cmn
  ),
  $spacers
);


// ---------------------------------
// Sizes
// ---------------------------------
// $sizes
// ex) .w-90 = width: 90%;
// ------------------------------
$sizes: () !default;
$sizes: map-merge(
  (
    // ↓[note] Already defined
    // 25: 25%,
    // 50: 50%,
    // 75: 75%,
    // 100: 100%,
    // auto: auto
    // ↑[note] Already defined
    90: 90%,
    80: 80%,
    70: 70%,
    60: 60%,
    40: 40%,
    30: 30%,
    900px: 900px,
    800px: 800px,
    700px: 700px,
    600px: 600px,
    500px: 500px,
    400px: 400px,
    300px: 300px
  ),
  $sizes
);


// ---------------------------------
// Grid breakpoints
// ---------------------------------
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// );


// ---------------------------------
// Grid containers
// ---------------------------------
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// );


// ---------------------------------
// Various settings
// ---------------------------------
// $enable-rounded: false;
// $enable-grid-classes: true;
// $enable-gradients: false;
// $enable-shadows: false;
// $enable-transitions: false;


// ---------------------------------
// Buttons + Forms
// ---------------------------------
// $input-btn-padding-y: 0.5rem;
// $btn-padding-x: 2rem;


// ---------------------------------
// z-index
// ---------------------------------
// $zindex-dropdown:          1000 !default;
// $zindex-sticky:            1020 !default;
// $zindex-fixed:             1030 !default;
// $zindex-modal-backdrop:    1040 !default;
// $zindex-modal:             1050 !default;
// $zindex-popover:           1060 !default;
// $zindex-tooltip:           1070 !default;
$zindex-header: 10;


// ---------------------------------
// Mediaquery
// ---------------------------------
// $xs: 320px;
// $sm: 500px;
// $md: 767px;
// $lg: 1000px;
// $xl: 1440px;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-padding-y:                0;
$dropdown-item-padding-y:           1.75rem;
$dropdown-item-padding-x:           2rem;
$dropdown-link-hover-bg:            transparent;
$dropdown-link-active-bg:           transparent;
$modaal-padding:                    30px;
