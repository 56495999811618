@mixin aic-normal-title() {
  font-weight: bold;
  font-size: 16px;
}

.Sec-aic-main {
  background-color: rgba(255, 255, 255, 0.8);
}

// .aic-form-set {}

.aic-form-normal-title {
  @include aic-normal-title;
}

.aic-form-normal-selector {
  &__title {
    @include aic-normal-title;
  }

  &__select {
    cursor: pointer;
  }
}

// .aic-form-radar-canvas {
//   &__canvas {}
// }

.aic-form-radar-btn-set {
  &__label {
    font-size: 14px;
    font-weight: bold;
  }

  &__btn {
    i {
      cursor: pointer;
      font-size: 32px;
      color: $blue;
    }
  }
}

.aic-form-slider {
  text-align: center;
  position: relative;
  margin: 0 auto 5px auto;
  padding: 15px 15% 0 15%;
  min-height: 40px;

  @include media-breakpoint-down(sm) {
    padding: 15px 20% 0 20%;
  }

  &__label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    // font-weight: bold;
    color: #333;
    z-index: 9;
    line-height: 1;
    width: 14%;
    // word-break: break-all;
    // overflow-wrap: break-word;

    @include media-breakpoint-down(sm) {
      width: 14%;
      font-size: 10px;
    }

    &--loc_left {
      left: 0;
    }

    &--loc_right {
      right: 0;
    }
  }
}

.aic-form-submit-btn {
  font-weight: bold;
  text-decoration: none;
  min-width: 260px;
  padding: 10px 20px;
  color: $white;

  &:hover { color: $white; }
}

.planner-no-item-msg {
  background-color: #eee;
  border: 1px dotted #aaa;
}
