// ========================================
// error404
// ========================================

.Sec-error404-main {
  min-height: 500px;
}

.page-not-found-box {
  margin-top: 100px;
  padding: 30px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px dotted #fff;
  text-align: center;

  // &__ttl {}

  // &__description {}

  &__go-home-link {
    color: $blue;
    font-weight: bold;

    &:hover {
      color: darken($blue, 10%);
    }
  }
}

