// ========================================
// utilities.scss
// ========================================
/* stylelint-disable */

// Object-fit
// ------------------------------
.object-fit-cover-cc {
  object-fit: cover;
  object-position: center center;
}

.object-fit-contain-cc {
  object-fit: contain;
  object-position: center center;
}


// ---------------------------------
// Colors
// ---------------------------------

// text
// ------------------------------
// *** globalf
.text-white { color: colors("white") !important; }
.text-black { color: colors("black")  !important; }
.text-gray { color: colors("gray") !important; }
.text-blue { color: colors("blue") !important; }
// *** role
.text-body { color: $body-color !important; }
.text-body-bg { color: $body-bg !important; }
.text-body-rvs { color: $body-rvs-color !important; }
.text-body-rvs-bg { color: $body-rvs-bg !important; }

// Font Override
// ------------------------------
.font-size-xl,
.h1 {
  line-height: 68px;
}

.font-size-lg,
.h2 {
  @include tracking(50);
  line-height: 48px;
}

.font-size-md,
.h3 {
  @include tracking(75);
  line-height: 34px;

  &.see-do {
    font-size: 3.6rem !important;
    @include tracking(50);
    line-height: 46px;
  }
  .lang-th &,.lang-zh-tw & {
    &.see-do {
      font-size: 3.6rem !important;
    }
  }
}

.font-size-sm,
.h4 {
  @include tracking(75);
  line-height: 28px;
}

.h5 {
  @include tracking(75);
  line-height: 25px;
}

.font-size-xs,
.h6 {
  @include tracking(75);
  line-height: 20px;
}

.font-family-title {
  font-family: 'Frank Ruhl Libre';
  font-weight: 700 !important;
  .lang-th & {
    font-family: 'Kanit';
    font-weight: 500 !important;
  }
  .lang-zh-tw & {
    font-family: 'Noto Sans TC';
    font-weight: 700 !important;
  }
  .lang-ko & {
    font-family: 'Noto Sans KR';
  }
}

@include media-breakpoint-down(sm) {
  .font-size-xl,
  h1,
  .h1 {
    line-height: 48px;

    //.lang-th & {
    //  line-height: 64px;
    //}
    //.lang-zh-tw & {
    //  line-height: 64px;
    //}
  }

  .font-size-lg,
  h2,
  .h2 {
    @include tracking(0);
    line-height: 38px;
  }

  .font-size-md,
  h3,
  .h3 {
    @include tracking(50);
    line-height: 28px;
  }

  .font-size-sm,
  h4,
  .h4 {
    @include tracking(75);
    line-height: 23px;
  }

  .lead,
  h5,
  .h5 {
    @include tracking(75);
    line-height: 21px;
  }

  .font-size-xs,
  h6,
  .h6 {
    @include tracking(75);
    line-height: 20px;
  }
}


// bg
// ------------------------------
// *** global
.bg-body { background-color: $body-bg !important; }
.bg-white { background-color: colors("white") !important; }
.bg-black { background-color: colors("black") !important; }
.bg-gray { background-color: colors("gray") !important; }
.bg-yellow { background-color: colors("yellow") !important; }
.bg-blue { background-color: colors("blue") !important; }


// ---------------------------------
// Typo
// ---------------------------------
.font-size-xl { font-size: $font-size-xl !important;
  .lang-th & {
    font-size: $font-size-xl-th !important;
  }
  .lang-zh-tw & {
    font-size: $font-size-xl-tw !important;
  }
}
.font-size-lg { font-size: $font-size-lg !important;
  .lang-th & {
    font-size: $font-size-lg-th !important;
  }
  .lang-zh-tw & {
    font-size: $font-size-lg-tw !important;
  }
}
.font-size-md { font-size: $font-size-md !important;
  .lang-th & {
    font-size: $font-size-md-th !important;
  }
  .lang-zh-tw & {
    font-size: $font-size-md-tw !important;
  }
}
.font-size-sm { font-size: $font-size-sm !important;
  .lang-th & {
    font-size: $font-size-sm-th !important;
  }
  .lang-zh-tw & {
    font-size: $font-size-sm-tw !important;
  }
}
.font-size-xs { font-size: $font-size-xs !important;
  //.lang-th & {
  //  font-size: $font-size-xs-th !important;
  //}
  .lang-zh-tw & {
    font-size: $font-size-xs-tw !important;
  }
}

// SMP時のみ、英語スタイルに合わせる
@include media-breakpoint-down(sm) {
  .font-size-xl { font-size: $font-size-xl-sp !important;
    .lang-th & {
      font-size: $font-size-xl-sp !important;
    }
    .lang-zh-tw & {
      font-size: $font-size-xl-sp !important;
    }
  }
  .font-size-lg { font-size: $font-size-lg-sp !important;
    .lang-th & {
      font-size: $font-size-lg-sp !important;
    }
    .lang-zh-tw & {
      font-size: $font-size-lg-sp !important;
    }
  }
  .font-size-md { font-size: $font-size-md-sp !important;
    .lang-th & {
      font-size: $font-size-md-sp !important;
    }
    .lang-zh-tw & {
      font-size: $font-size-md-sp !important;
    }
  }
  .font-size-sm { font-size: $font-size-sm-sp !important;
    .lang-th & {
      font-size: $font-size-sm-sp !important;
    }
    .lang-zh-tw & {
      font-size: $font-size-sm-sp !important;
    }
  }
  .font-size-xs { font-size: $font-size-xs-sp !important;
    .lang-th & {
      font-size: $font-size-xs-sp !important;
    }
    .lang-zh-tw & {
      font-size: $font-size-xs-sp !important;
    }
  }
  h1, .h1 { @include font-size($h1-font-size-sp);
    .lang-th & {
      @include font-size($h1-font-size-sp);
    }
    .lang-zh-tw & {
      @include font-size($h1-font-size-sp);
    }
  }
  h2, .h2 { @include font-size($h2-font-size-sp);
    .lang-th & {
      @include font-size($h2-font-size-sp);
    }
    .lang-zh-tw & {
      @include font-size($h2-font-size-sp);
    }
  }
  h3, .h3 { @include font-size($h3-font-size-sp);
    .lang-th & {
      @include font-size($h3-font-size-sp);
    }
    .lang-zh-tw & {
      @include font-size($h3-font-size-sp);
    }
  }
  h4, .h4 { @include font-size($h4-font-size-sp);
    .lang-th & {
      @include font-size($h4-font-size-sp);
    }
    .lang-zh-tw & {
      @include font-size($h4-font-size-sp);
    }
  }
  h5, .h5 { @include font-size($h5-font-size-sp);
    .lang-th & {
      @include font-size($h5-font-size-sp);
    }
    .lang-zh-tw & {
      @include font-size($h5-font-size-sp);
    }
  }
  h6, .h6 { @include font-size($h6-font-size-sp);
    .lang-th & {
      @include font-size($h6-font-size-sp);
    }
    .lang-zh-tw & {
      @include font-size($h6-font-size-sp);
    }
  }
  .lead { @include font-size($lead-font-size-sp);
    .lang-th & {
      @include font-size($lead-font-size-sp);
    }
    .lang-zh-tw & {
      @include font-size($lead-font-size-sp);
    }
  }
}

.line-height-1 { line-height: 1; }
.line-height-1point2 { line-height: 1.2; }
.line-height-1point3 { line-height: 1.3; }


// ---------------------------------
// Sizes
// ---------------------------------

// width / height
// ------------------------------
// [note] Extended Bootstrap $sizes / use Bootstrap utilities.
// ex) .w-300px = width: 300px;
// ex) .h-30 = height: 30%;

// max-width
// ------------------------------
// [note] Extended Bootstrap $sizes and difined my utilities below / use my utilities.
// ex) .mw-500px = max-width: 500px;
// ex) .mw-50 = max-width: 50%;
@each $key, $val in $sizes {
  .mw-#{$key} {
    max-width: #{$val} !important;
  }
}

// glid-width
// ------------------------------
// extend bootstrap 指定したサイズ以上がその値%でfixされる。 w-100やw-75などと併用する
.w-xs-10 { @include media-breakpoint-up(xs) { width: 10% !important; } }
.w-sm-10 { @include media-breakpoint-up(sm) { width: 10% !important; } }
.w-md-10 { @include media-breakpoint-up(md) { width: 10% !important; } }
.w-lg-10 { @include media-breakpoint-up(lg) { width: 10% !important; } }
.w-xs-20 { @include media-breakpoint-up(xs) { width: 20% !important; } }
.w-sm-20 { @include media-breakpoint-up(sm) { width: 20% !important; } }
.w-md-20 { @include media-breakpoint-up(md) { width: 20% !important; } }
.w-lg-20 { @include media-breakpoint-up(lg) { width: 20% !important; } }
.w-xs-25 { @include media-breakpoint-up(xs) { width: 25% !important; } }
.w-sm-25 { @include media-breakpoint-up(sm) { width: 25% !important; } }
.w-md-25 { @include media-breakpoint-up(md) { width: 25% !important; } }
.w-lg-25 { @include media-breakpoint-up(lg) { width: 25% !important; } }
.w-xs-30 { @include media-breakpoint-up(xs) { width: 30% !important; } }
.w-sm-30 { @include media-breakpoint-up(sm) { width: 30% !important; } }
.w-md-30 { @include media-breakpoint-up(md) { width: 30% !important; } }
.w-lg-30 { @include media-breakpoint-up(lg) { width: 30% !important; } }
.w-xs-40 { @include media-breakpoint-up(xs) { width: 40% !important; } }
.w-sm-40 { @include media-breakpoint-up(sm) { width: 40% !important; } }
.w-md-40 { @include media-breakpoint-up(md) { width: 40% !important; } }
.w-lg-40 { @include media-breakpoint-up(lg) { width: 40% !important; } }
.w-xs-50 { @include media-breakpoint-up(xs) { width: 50% !important; } }
.w-sm-50 { @include media-breakpoint-up(sm) { width: 50% !important; } }
.w-md-50 { @include media-breakpoint-up(md) { width: 50% !important; } }
.w-lg-50 { @include media-breakpoint-up(lg) { width: 50% !important; } }
.w-xs-60 { @include media-breakpoint-up(xs) { width: 60% !important; } }
.w-sm-60 { @include media-breakpoint-up(sm) { width: 60% !important; } }
.w-md-60 { @include media-breakpoint-up(md) { width: 60% !important; } }
.w-lg-60 { @include media-breakpoint-up(lg) { width: 60% !important; } }
.w-xs-70 { @include media-breakpoint-up(xs) { width: 70% !important; } }
.w-sm-70 { @include media-breakpoint-up(sm) { width: 70% !important; } }
.w-md-70 { @include media-breakpoint-up(md) { width: 70% !important; } }
.w-lg-70 { @include media-breakpoint-up(lg) { width: 70% !important; } }
.w-xs-75 { @include media-breakpoint-up(xs) { width: 75% !important; } }
.w-sm-75 { @include media-breakpoint-up(sm) { width: 75% !important; } }
.w-md-75 { @include media-breakpoint-up(md) { width: 75% !important; } }
.w-lg-75 { @include media-breakpoint-up(lg) { width: 75% !important; } }
.w-xs-80 { @include media-breakpoint-up(xs) { width: 80% !important; } }
.w-sm-80 { @include media-breakpoint-up(sm) { width: 80% !important; } }
.w-md-80 { @include media-breakpoint-up(md) { width: 80% !important; } }
.w-lg-80 { @include media-breakpoint-up(lg) { width: 80% !important; } }
.w-xs-85 { @include media-breakpoint-up(xs) { width: 85% !important; } }
.w-sm-85 { @include media-breakpoint-up(sm) { width: 85% !important; } }
.w-md-85 { @include media-breakpoint-up(md) { width: 85% !important; } }
.w-lg-85 { @include media-breakpoint-up(lg) { width: 85% !important; } }
.w-xs-90 { @include media-breakpoint-up(xs) { width: 90% !important; } }
.w-sm-90 { @include media-breakpoint-up(sm) { width: 90% !important; } }
.w-md-90 { @include media-breakpoint-up(md) { width: 90% !important; } }
.w-lg-90 { @include media-breakpoint-up(lg) { width: 90% !important; } }
.w-xs-100 { @include media-breakpoint-up(xs) { width: 100% !important; } }
.w-sm-100 { @include media-breakpoint-up(sm) { width: 100% !important; } }
.w-md-100 { @include media-breakpoint-up(md) { width: 100% !important } }
.w-lg-100 { @include media-breakpoint-up(lg) { width: 100% !important; } }
.w-xs-auto { @include media-breakpoint-up(xs) { width: auto !important; } }
.w-sm-auto { @include media-breakpoint-up(sm) { width: auto !important; } }
.w-md-auto { @include media-breakpoint-up(md) { width: auto !important; } }
.w-lg-auto { @include media-breakpoint-up(lg) { width: auto !important; } }
/* stylelint-enable */


// ---------------------------------
// Gutter
// ---------------------------------

.col-gutter-75 {
  @include col-space();
}

.col-gutter-65 {
  @include col-space($m:0.65);
}

.col-gutter-50 {
  @include col-space($m:0.5);
}

.col-gutter-40 {
  @include col-space($m:0.4);
}


// option - 文字間隔の定義
// ------------------------------
.letter-spacing-35 {
  @include tracking(35);
}

.letter-spacing-none {
  letter-spacing: 0;
}


// option - テキストを隠す際に利用
// ------------------------------
.text-hidden {
  @include text-hidden;
}


// border-option
// ------------------------------
// Bootstrapのボーダー利用時の追加オプション
.border,
.border-top,
.border-right,
.border-bottom,
.border-left {
  &--width-bold {
    border-width: 3px !important;
  }

  &--white-alpha {
    &-10 {
      border-color: colors("alpha-white-10") !important;
    }

    &-20 {
      border-color: colors("alpha-white-20") !important;
    }

    &-30 {
      border-color: colors("alpha-white-30") !important;
    }

    &-40 {
      border-color: colors("alpha-white-40") !important;
    }

    &-50 {
      border-color: colors("alpha-white-50") !important;
    }

    &-60 {
      border-color: colors("alpha-white-60") !important;
    }

    &-70 {
      border-color: colors("alpha-white-70") !important;
    }

    &-80 {
      border-color: colors("alpha-white-80") !important;
    }

    &-90 {
      border-color: colors("alpha-white-90") !important;
    }
  }

  &--white {
    border-color: colors("white") !important;
  }

  &--black {
    border-color: colors("black") !important;
    box-sizing: content-box;
  }

  &--blue {
    border-color: colors("blue") !important;
  }
}

@include media-breakpoint-down(sm) {
  .border,
  .border-top,
  .border-right,
  .border-bottom,
  .border-left {
    &--width-bold {
      border-width: 5px !important;
    }
  }
}

// padding
// ------------------------------
// モバイル表示時の両端の共通スペース
@include media-breakpoint-down(sm) {
  .px-space {
    padding: 0 1.5rem;
  }
}

// full-width
// ------------------------------
.w-full {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  position: relative;
  overflow-x: hidden;

  @media (min-width:768px) and (max-width: 1080px) {
    margin-left: 0;
  }
}

// position
// ------------------------------
.top {
  &-0 {
    top: 0 !important;
  }

  &-n1 {
    top: -0.25rem !important;
  }

  &-n2 {
    top: -0.5rem !important;
  }

  &-n3 {
    top: -1rem !important;
  }

  &-n4 {
    top: -2rem !important;
  }

  &-n5 {
    top: -3rem !important;
  }

  &-n6 {
    top: -4rem !important;
  }

  &-n7 {
    top: -5rem !important;
  }

  &-n8 {
    top: -6rem !important;
  }
}

.right {
  &-0 {
    right: 0 !important;
  }

  &-n1 {
    right: -0.25rem !important;
  }

  &-n2 {
    right: -0.5rem !important;
  }

  &-n3 {
    right: -1rem !important;
  }

  &-n4 {
    right: -2rem !important;
  }

  &-n5 {
    right: -3rem !important;
  }

  &-n6 {
    right: -4rem !important;
  }

  &-n7 {
    right: -5rem !important;
  }

  &-n8 {
    right: -6rem !important;
  }
}

.bottom {
  &-0 {
    bottom: 0 !important;
  }

  &-n1 {
    bottom: -0.25rem !important;
  }

  &-n2 {
    bottom: -0.5rem !important;
  }

  &-n3 {
    bottom: -1rem !important;
  }

  &-n4 {
    bottom: -2rem !important;
  }

  &-n5 {
    bottom: -3rem !important;
  }

  &-n6 {
    bottom: -4rem !important;
  }

  &-n7 {
    bottom: -5rem !important;
  }

  &-n8 {
    bottom: -6rem !important;
  }
}

.left {
  &-0 {
    left: 0 !important;
  }

  &-n1 {
    left: -0.25rem !important;
  }

  &-n2 {
    left: -0.5rem !important;
  }

  &-n3 {
    left: -1rem !important;
  }

  &-n4 {
    left: -2rem !important;
  }

  &-n5 {
    left: -3rem !important;
  }

  &-n6 {
    left: -4rem !important;
  }

  &-n7 {
    left: -5rem !important;
  }

  &-n8 {
    left: -6rem !important;
  }
}

.centering-x {
  @include centering(transform-x);
}

.centering-y {
  @include centering(transform-y);
}

.centering {
  @include centering();
}


// z-index
// ------------------------------
.z-index {
  &-n10 {
    z-index: -10;
  }

  &-n5 {
    z-index: -5;
  }

  &-n1 {
    z-index: -1;
  }

  &-0 {
    z-index: 0;
  }

  &-1 {
    z-index: 1;
  }

  &-5 {
    z-index: 5;
  }

  &-10 {
    z-index: 10;
  }
}


// pointer-events
// ------------------------------
.pointer-events {
  &-none {
    pointer-events: none;
  }

  &-auto {
    pointer-events: auto;
  }
}


// Width 幅の固定
.w-define {
  max-width: 1080px;
  min-width: 1080px;
  margin: auto;
}

@include media-breakpoint-down(sm) {
  .w-define {
    max-width: unset;
    min-width: unset;
    //padding: 0 1.5rem;
    width: calc(100% - 3rem);
  }
}

// Don't use max-width
.w-nm100 {
  max-width: initial;
}

// object-fit
.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;
  height: 100%;
}

// IE11 hack
_:lang(x)::-ms-backdrop,img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}
_:lang(x)::-ms-backdrop, .highlights__inner img,
_:lang(x)::-ms-backdrop, .see-do__inner img {
  width: auto;
  height: auto;
  flex-shrink: 0;
}
_:lang(x)::-ms-backdrop, .ie11-img {
  width: auto;
}

_:lang(x)::-ms-backdrop, .ie11-sidebar-row {
  width: 257px;
}

// EDGE hack
@media (min-width:768px) and (max-width: 1235px) {
  _:-ms-lang(x)::backdrop, .edge-main-width {
    width: auto;
    min-width: 1235px; // 918px + 317px
  }
}

// Font Weight Style
.font-weight-lg-normal {
  @include media-breakpoint-up(lg) {
    font-weight: normal !important;
  }
}
