// ========================================
// heading
// ========================================

.heading {
  margin: 0 0 1.5rem 0;

  &--type_1 {
    font-size: 3rem;
  }

  &--type_2 {
    margin-bottom: 1.2rem;
    font-size: 2.4rem;
  }
}
