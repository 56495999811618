// ========================================
// Travle info
// ========================================

// ---------------------------------
// .travel-info-access
// ---------------------------------

.travel-info-access-btn {
  $_access-btn-allow-content: ".travel-info-access-btn__allow-content";

  background-color: color("black");
  display: inline-block;
  text-decoration: none;
  padding: 3rem 4rem;

  &:hover {
    text-decoration: none;

    #{$_access-btn-allow-content} {
      width: 120%;
    }
  }

  &__inner {
    display: flex;
  }

  &__left {
    margin-right: 1rem;
  }

  &__svg {
    height: 5rem;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__allow {
    position: relative;
  }

  &__allow-content {
    background: url(../images/common/c-arrow-white-right.png) no-repeat bottom right;
    background-size: auto 12px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    width: 60px;
    height: 12px;
    padding-right: 0px;
  }
}
