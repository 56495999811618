// ========================================
// Plan Your Trip
// ========================================

// ---------------------------------
// .pyt-btn
// ---------------------------------

.pyt-btn {
  $_root: &;
  $_pyt-btn-icon: $_root + "__icon";
  $_pyt-btn-aiplan-text: $_root + "__text" + "--type_aiplan";
  $_pyt-btn-myplan-text: $_root + "__text" + "--type_myplan";

  &__link {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 100%;
    padding: 2rem 0;

    &--type_aiplan {
      background: colors("black");
      border: solid 1px colors("black");
    }

    &--type_myplan {
      background-color: colors("yellow");
      border: solid 1px colors("yellow");
    }

    &:hover {
      background-color: colors("black");
      border: solid 1px colors("white");
      text-decoration: none;
      transition: all 0.3s;

      > #{$_pyt-btn-icon} {
        background-color: colors("yellow");
        transition: all 0.3s;
      }

      > #{$_pyt-btn-myplan-text} {
        color: colors("yellow");
        transition: all 0.3s;
      }
    }
  }

  &__icon {
    background-color: colors("white");
    border-radius: 50%;
    color: colors("black");
    padding: 1.2rem;
  }

  &__text {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;

    &--type_aiplan {
      color: colors("white");
    }

    &--type_myplan {
      color: colors("black");
    }
  }

  &__subtext {
    display: block;
    font-size: 1.2rem;
    text-transform: initial;
  }
}

// ---------------------------------
// .pyt-theme-item
// ---------------------------------

.pyt-theme-item {
  $_root: &;
  $_pyt-theme-item-img: $_root + "__img";

  &__link {
    display: block;
    margin: 0 0.1rem;
    overflow: hidden;

    &:hover {
      color: colors("white");

      > #{$_pyt-theme-item-img} {
        transform: scale(1.2);
        transition: all 0.3s;
      }
    }
  }

  &__img {
    opacity: 0.6;
  }

  &__ttl {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 1rem;
    margin: 0;
  }
}

// ---------------------------------
// .pyt-seedo-item
// ---------------------------------

.pyt-seedo-item {
  $_root: &;
  $_pyt-seedo-item-img: $_root + "__img";

  &__link {
    display: block;
    overflow: hidden;

    &:hover {
      color: colors("white");

      > picture #{$_pyt-seedo-item-img} {
        transform: scale(1.2);
        transition: all 0.3s;
      }
    }
  }

  &__img {
    opacity: 0.6;
  }

  &__ttl {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 1rem;
    margin: 0;
  }
}

// ---------------------------------
// .plan-your-trip
// ---------------------------------

.plan-your-trip {
  // A.I.Route Planner
  .p-ai-route-planner {
    position: relative;
    width: 100%;
    bottom: auto;

    &__ele {
      padding: 3rem 3rem 3rem 10rem;
      background-size: 48px 57px;
      background-position: center left 3rem;

      // .c-link-arrow-adjacent--right::before {
      //   right: 10px;
      // }

      &--mod {
        line-height: auto;

        &-link {
          background: none;
          padding-left: 0;
        }
      }
    }

    // add by smmt in 200403
    // [note 1] p-ai-route-plannerブロックはトップページにも定義されている。
    // [note 2] 以下elementは、plan-your-tripのindexページにのみこのクラス名とスタイルを与えた。
    // [todo] 1webサイトにおいて本クラスの有無が生まれているので解決する必要がある
    &__ele-txt {
      @include media-breakpoint-down(xs) {
        margin-right: 50px;
      }
    }
  }

  .p-link-access {
    background: colors("black");
    height: 100%;
    width: 100%;
    min-height: 132px;

    &::before {
      content: '';
      width: 36px;
      height: 50px;
      display: block;
      background: url(../images/common/c-icon-pin-w.svg) no-repeat center center;
      background-size: 36px auto;
      position: absolute;
      left: 30px;

      @include centering(transform-y);
    }

    &__base {
      padding: 0 0 0 80px;
      width: 100%;
      height: 100%;
    }

    @include media-breakpoint-up(md) {
      &:hover {
        .c-link-arrow-fixed::before {
          width: 100px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &:active {
        .c-link-arrow-fixed::before {
          width: 100px;
        }
      }
    }
  }

  // View My Plan
  .p-link-view-myplan {
    text-decoration: none !important;
    font-weight: bold;
    height: 100%;
    min-height: 132px;

    &__base {
      background: colors("yellow");
      width: 100%;
      height: 100%;

      @include transition();

      &--text {
        color: colors("black");
        display: block;
        padding: 64px 0 16px;
        position: relative;
        text-align: center;
        white-space: nowrap;

        @include transition();

        &::before {
          content: '';
          width: 40px;
          height: 40px;
          display: block;
          border-radius: 50%;
          box-sizing: border-box;
          background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
          background-size: 16px auto;
          background-color: colors("white");
          position: absolute;
          top: 16px;

          @include centering(transform-x);
          @include transition();
        }
      }
    }

    @include media-breakpoint-up(md) {
      &:hover {
        .p-link-view-myplan__base {
          background: colors("black");

          .p-link-view-myplan__base--text {
            color: colors("yellow");

            &::before {
              background-color: colors("yellow");
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &:active {
        .p-link-view-myplan__base {
          background: colors("black");

          .p-link-view-myplan__base--text {
            color: colors("yellow");

            &::before {
              background-color: colors("yellow");
            }
          }
        }
      }
    }
  }

  // Contents
  .c-link-arrow {
    position: relative;
    display: inline-block;

    @extend .text-decoration-none;
    @extend .font-weight-bold;

    &-longarrow {
      &::before {
        content: '';
        width: 80%;
        height: 12px;
        display: block;
        position: absolute;

        @include transition();
      }

      &--arrow-blue {
        &::before {
          background: url(../images/common/c-arrow-blue-right.png) no-repeat;
          background-position: bottom right;
          background-size: auto 12px;

          @include centering(transform-y);
        }
      }
    }
  }

  // shareButton
  .btn.share {
    width: 38px;
    height: 38px;
  }

  // hover override
  .p-card {
    @include media-breakpoint-up(md) {
      &:hover {
        // A.I.Route Planner hover override
        .p-card---hover .c-link-arrow-adjacent::before {
          //width: calc(60px + 60px);
          width: 120px;
          right: -120px;
        }

        // c-link-arrow-longarrow--arrow-blue hover override
        .p-card--hover .c-link-arrow-longarrow::before {
          //width: calc(100% - 0px);
          width: 100%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &:active {
        // A.I.Route Planner hover override
        .p-card---hover .c-link-arrow-adjacent::before {
          //width: calc(60px + 60px);
          width: 120px;
          right: -120px;
        }

        // c-link-arrow-longarrow--arrow-blue hover override
        .p-card--hover .c-link-arrow-longarrow::before {
          //width: calc(100% - 0px);
          width: 100%;
        }
      }
    }
  }

  .c-icon {
    padding-left: 67px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      width: 42px;
      height: 42px;
      display: block;
      left: 0;

      @include centering(transform-y);
      @include transition();
    }

    &--by-bicycle-white {
      &::before {
        background: url("../images/common/c-icon-by-bicycle-w.png");
        background-size: cover;
      }
    }

    &--by-bus-white {
      &::before {
        background: url("../images/common/c-icon-by-bus-w.png");
        background-size: cover;
      }
    }

    &--by-car-white {
      &::before {
        background: url("../images/common/c-icon-by-car-w.png");
        background-size: cover;
      }
    }

    &--by-train-white {
      &::before {
        background: url("../images/common/c-icon-by-train-w.png");
        background-size: cover;
      }
    }

    &--by-walk-white {
      &::before {
        background: url("../images/common/c-icon-by-walk-w.png");
        background-size: cover;
      }
    }

    //&--border {
    //  &::after {
    //    content: "";
    //    height: 3px;
    //    width: calc(100% - 2rem);
    //    margin-left: 2rem;
    //    background: white;
    //    @include centering(transform-y);
    //  }
    //}
  }

  &__swiper-img-wrap {
    height: 270px;
  }

  &__swiper-inner-img-wrap {
    height: 217px;
  }
}

// [note] Add by smmt 20.03.30
.plan-your-trip-show-desc-link {
  color: $primary;

  &:hover {
    color: darken($primary, 15%);
  }
}
