// ========================================
// _trekking
// ========================================

// ---------------------------------
// .trekkign-upper
// ---------------------------------

.trekking-upper {
  &__item-img {
    height: 20rem;

    @include media-breakpoint-down(md) {
      height: 50vw;
    }
  }
}

// ---------------------------------
// .trekkign-course-tab
// ---------------------------------

.trekking-course-tab {
  $_root: &;
  $_input-1: $_root + "__input--type_1";
  $_input-2: $_root + "__input--type_2";
  $_labels: $_root + "__labels";
  $_contents: $_root + "__contents";
  $_bottom-label: $_root + "__bottom-label";
  $_bottom-label-inner: $_root + "__bottom-label-inner";

  &__input {
    display: none;
  }

  &__labels {
    border: solid 3px colors('white');
    display: flex;
    max-width: 800px;
    margin: auto;
  }

  &__label {
    background-color: transparent;
    color: colors('white');
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-align: center;
    transition: ease 0.2s opacity;
    position: relative;
    width: 50%;
    padding: 12px;
    margin: 0;

    &:hover {
      color: #d9d9d9;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &--type_1 {
      #{$_input-1}:checked ~ #{$_labels} & {
        background-color: colors('white');
        color: $blue;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 10px 0 10px;
          border-color: colors('white') transparent transparent transparent;
          bottom: -14px;
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
    }

    &--type_2 {
      #{$_input-2}:checked ~ #{$_labels} & {
        background-color: colors('white');
        color: $blue;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 10px 0 10px;
          border-color: colors('white') transparent transparent transparent;
          bottom: -14px;
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
    }
  }

  &__label-icon {
    width: auto;
    height: 3rem;
    margin: 0 1rem 0 0;

    @include media-breakpoint-down(sm) {
      margin: 0 0 1rem 0;
    }
  }

  &__label-txt {
    margin: 0;
  }

  &__content {
    display: none;

    &--type_1 {
      #{$_input-1}:checked ~ #{$_contents} & {
        display: block;
      }
    }

    &--type_2 {
      #{$_input-2}:checked ~ #{$_contents} & {
        display: block;
      }
    }
  }

  &__bottom {
    text-align: center;
    padding: 0 2rem;
  }

  &__bottom-label {
    background-color: colors('white');
    cursor: pointer;
    padding: 1.5rem 1rem;
    margin: 0;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  &__bottom-label-inner {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }

  &__bottom-icon {
    width: auto;
    height: 3rem;
    margin-right: 1rem;
  }

  &__bottom-txt {
    margin: 0;
    position: relative;
    display: inline-block;
    padding-right: 1rem;
    margin: 0 120px 0 0;

    &:before {
      background: url(../images/common/c-arrow-blue-right.png) no-repeat bottom right;
      background-size: auto 12px;
      content: '';
      width: 60px;
      height: 12px;
      display: block;
      position: absolute;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      right: -60px;
      padding-right: 0px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      #{$_bottom-label}:hover #{$_bottom-label-inner} > & {
        width: 120px;
        right: -120px;
      }
    }
  }
}

// ---------------------------------
// .trekkign-course-content
// ---------------------------------

.trekking-course-content {
  background-color: colors('white');
  padding: 3rem 2rem;

  $_root: &;
  $_detail-link: $_root + "__detail-link";

  &__ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__ttl-icon {
    margin-right: 1.5rem;

    &--type_trekking {
      width: 3rem;
    }

    &--type_olle {
      width: auto;
      height: 4rem;
    }
  }

  &__img {
    width: 100%;
    object-fit: cover;

    &--type_big {
      height: 100%;
    }

    &--type_small {
      height: 100%;
    }
  }

  &__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: none;
    margin: 0 2rem 2rem 0;

    &:hover {
      text-decoration: none;
    }
  }

  &__lead {
    width: 80%;
    margin: auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__center {
    display: flex;
    margin: 0 -2rem;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__center-ttl {
    background-color: #2b4e76;
    color: colors('white');
    display: flex;
    align-items: center;
    height: 100%;
    padding: 2rem;
    margin: 0;

    @include media-breakpoint-down(sm) {
      padding: 2rem 1rem;
    }
  }

  &__center-other {
    display: flex;
    align-items: center;
    height: 50%;
    padding: 1rem 2rem;
    margin: 0;

    @include media-breakpoint-down(sm) {
      width: 50%;
      height: auto;
      padding: 1rem;
    }

    &--status_distance {
      background-color: #414141;
      color: colors('white');
    }

    &--status_time {
      background-color: #c2d7ec;
    }
  }

  &__pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__pdf-img {
    width: 100%;
    object-fit: contain;

    @include media-breakpoint-down(md) {
      height: 30rem;
    }
  }

  &__pdf-icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
    margin: 0 0 2rem;

    &:hover {
      text-decoration: none;
    }
  }

  &__details {
    background-color: #E9E9E9;
    overflow: scroll;
    height: 500px;
    padding: 2rem;

    @include media-breakpoint-down(md) {
      overflow: auto;
      height: auto;
    }
  }

  &__detail {
    display: flex;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__detail-img {
    width: 50%;
    padding-right: 2rem;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0;
      margin-bottom: 1rem;
    }
  }

  &__detail-txts {
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__detail-link {
    background-color: white;
    border: solid 2px colors("blue");
    display: block;
    padding: 1.5rem 1rem;
  }

  &__detail-link-txt {
    position: relative;
    display: inline-block;
    padding-right: 1rem;
    margin: 0;

    &:before {
      background: url(../images/common/c-arrow-blue-right.png) no-repeat bottom right;
      background-size: auto 12px;
      content: '';
      width: 60px;
      height: 12px;
      display: block;
      position: absolute;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      right: -60px;
      padding-right: 0px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      #{$_detail-link}:hover > & {
        width: 120px;
        right: -120px;
      }
    }
  }

  &__distance {
    display: flex;
    align-items: center;
    margin: 2rem 0;
  }

  &__distance-icon {
    width: 3rem;
    margin-right: 2rem;
  }

  &__distance-txt {
    background-color: colors('white');
    padding: 1rem;
    margin: 0;
  }

  &__viewmore {
    position: relative;
    text-align: center;
    margin: 4rem 0;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &[data-toggle="collapse"].collapsed {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }
  }

  &__viewmore-border {
    border-style: dashed;
    border-color: colors("gray");
    position: absolute;
    top: 50%;
    width: 100%;
    margin: 0;
  }

  &__viewmore-btn {
    display: inline-block;
    border: solid 1px #CF396B;
    background-color: colors('white');
    color: #CF396B;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    padding: 1rem 2rem;

    &:hover {
      color: #CF396B;
      text-decoration: none;
    }
  }

  &__viewmore-inner {
    display: flex;
    align-items: center;
  }

  &__viewmore-txt {
    margin: 0 1rem 0 0;
  }

  &__btns {
    display: inline-block;

    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

  &__btn {
    background-color: rgba(30, 30, 30, 0.8);
    border: none;
    color: colors("white");
    display: inline-block;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    padding: 2rem;

    &:hover {
      background: colors("black");
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__btn-inner {
    display: inline-block;
    position: relative;
  }

  &__btn-icon {
    font-size: 3rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
