// ========================================
// heading
// ========================================

.c-icon {
  &--search {
    background: url(../images/common/c-icon-head-search.svg) no-repeat;
    background-size: contain;
  }

  &--menu {
    background: url(../images/common/c-icon-head-menu.svg) no-repeat;
    background-size: contain;
  }

  &--arrow {
    background: url(../images/common/c-icon-arrow.svg) no-repeat;
    background-size: contain;
  }

  &--close {
    background: url(../images/common/c-icon-close.svg) no-repeat;
    background-size: contain;
  }

  &--twitter {
    background: url(../images/common/c-icon-twitter.svg) no-repeat;
    background-size: contain;
  }

  &--instagram {
    background: url(../images/common/c-icon-instagram.svg) no-repeat;
    background-size: contain;
  }

  &--check {
    background: url(../images/common/c-icon-check.svg) no-repeat;
    background-size: contain;
  }

  &--polygon {
    background: url(../images/common/c-icon-polygon.svg) no-repeat;
    background-size: contain;
  }

  &--ai-route-planner {
    background: url(../images/icon-ai-route-planner.svg) no-repeat;
    background-size: contain;
  }

  &--events {
    background: url(../images/common/c-icon-events.svg) no-repeat;
    background-size: contain;
  }

  &--tripadvisor {
    background: url(../images/common/c-icon-tripadvisor.svg) no-repeat;
    background-size: contain;
  }

  &--tripadvisor-small {
    background: url(../images/common/c-icon-tripadvisor-small.svg) no-repeat;
    background-size: contain;
  }

  &--position {
    background-position: center center;
  }
}

// ---------------------------------
// .cmn-icon-zoom
// ---------------------------------

.cmn-icon-zoom {
  background-color: colors("white");
  border-radius: 5rem;
  box-shadow: 2px 2px 8px 0px colors("black");
  color: colors("black");
  display: flex;
  align-items: center;
  padding: 1rem 2rem;

  &__txt {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0.5rem 0 0;
  }
}
