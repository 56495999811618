// ========================================
// Top
// ========================================
.home-background {
  background: url(../images/home/background.jpg) repeat-y top left;
  background-size: 193px auto;
  padding-top: 24rem;
  padding-bottom: 14rem;
  margin-top: -100px;
}

@include media-breakpoint-down(sm) {
  .home-background {
    background: none;
    // todo:Androidk系のバグあり、Galaxy仕様に調整
    padding-top: 18rem;
    padding-bottom: 5rem;
    margin-top: -186px;
  }
}


.home-slider {
  margin-top: -80px;
  overflow-x: hidden;
  //padding-bottom: 14rem;
}

// key animation
.p-anim-view {
  width: 100%;
  //height: 100%;
  background: colors("white");
  //opacity: .9;
  position: absolute;
  z-index: 2;

  @include aspect-ratio(2732,1492);


  &__loader {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: loader 1.8s infinite ease-in-out;
    color: colors("blue");
    font-size: 10px;
    //margin: 80px auto;
    //position: relative;
    text-indent: -9999em;
    //transform: translateZ(0);
    animation-delay: -0.16s;

    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      animation-fill-mode: both;
      animation: loader 1.8s infinite ease-in-out;
    }

    &::before {
      left: -3.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &::after {
      left: 3.5em;
    }
  }

  @keyframes loader {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  &__scene1 {
    width: 100%;
    height: 100%;
    background: url(../images/home/slider/scene1.jpg) no-repeat center center;
    background-size: cover;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;

    @include media-breakpoint-down(sm) {
      background: url(../images/home/slider/scene1-sp.jpg) no-repeat center center;
      background-size: cover;
    }

    &.is-animation {
      visibility: visible;
      animation: fadeIn 0.5s ease-in-out;
    }
  }

  &__scene2 {
    width: 100%;
    height: 100%;
    background: url(../images/home/slider/scene2.jpg) no-repeat center center;
    background-size: cover;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;

    @include media-breakpoint-down(sm) {
      background: url(../images/home/slider/scene2-sp.jpg) no-repeat center center;
      background-size: cover;
    }

    &.is-animation {
      visibility: visible;
      animation: fadeIn 0.5s ease-in forwards;
    }
  }

  &__scene3 {
    width: 100%;
    height: 100%;
    background: white;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;

    &.is-animation {
      visibility: visible;
      animation: fadeIn 0.5s ease-in forwards, fadeOut 1s ease-out 3s;
    }
  }

  &__scene4 {
    width: 100%;
    height: 100%;
    background: url(../images/home/slider/scene3.jpg) no-repeat center center;
    background-size: cover;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;

    @include media-breakpoint-down(sm) {
      background: url(../images/home/slider/scene3-sp.jpg) no-repeat center center;
      background-size: cover;
    }

    &.is-animation {
      visibility: visible;
      animation: fadeIn 0.5s ease-in forwards, fadeOut 1s ease-out 3s;
    }
  }

  @keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }

    1% {
      visibility: visible;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }

    99% {
      visibility: visible;
      opacity: 0;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

}

@include media-breakpoint-down(sm) {
  .p-anim-view {
    @include aspect-ratio(1500,2000);
  }
}

@include media-breakpoint-down(sm) {
  .home-slider {
    margin-top: 0;
    padding-bottom: 5rem;
  }
}


.home-ai-route-planner {
  position: relative;
  z-index: 2;
  margin-top: -80px;

  &::before {
    content: "";
    width: 160px;
    height: 160px;
    display: block;
    background: url(../images/icon-ai-route-planner.svg) no-repeat colors("black") top 23px center;
    background-size: 48px 57px;
    border-radius: 50%;
    top: -80px;
    z-index: -1;

    @include centering(transform-x);
  }
}

@include media-breakpoint-down(sm) {
  .home-ai-route-planner {
    width: calc(100% - 3rem);
    margin: auto;
    margin-top: -50px;

    &::before {
      width: 80px;
      height: 80px;
      background: url(../images/icon-ai-route-planner.svg) no-repeat colors("black") top 12px center;
      background-size: 24px 28.5px;
      top: -40px;
    }

    &__inner {
      &--form {
        height: auto;
      }
    }
  }
}

.c-ai-route-planner {
  select {
    padding: 0 14px 0 86px;
    width: 100%;
    height: 100%;
    font-size: $font-size-base * 1.176470588235294; // 20px
  }

  &__from {
    position: relative;

    &::before {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-from.svg) center center no-repeat;
      background-size: 29px 39px;
      display: block;
      width: 36px;
      height: 40px;
      overflow: hidden;
      pointer-events: none;
      left: 30px;

      @include centering(transform-y);
    }

    &::after {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-arrow.svg);
      background-size: contain;
      display: block;
      width: 10px;
      height: 8px;
      overflow: hidden;
      pointer-events: none;
      right: 0;

      @include centering(transform-y);
    }
  }

  &__duration {
    position: relative;

    &::before {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-duration.svg) center center no-repeat;
      background-size: 36px 40px;
      display: block;
      width: 36px;
      height: 40px;
      overflow: hidden;
      pointer-events: none;
      left: 30px;

      @include centering(transform-y);
    }

    &::after {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-arrow.svg);
      background-size: contain;
      display: block;
      width: 10px;
      height: 8px;
      overflow: hidden;
      pointer-events: none;
      right: 0;

      @include centering(transform-y);
    }
  }

  &__feeling {
    position: relative;

    &::before {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-feeling.svg) center center no-repeat;
      background-size: 34px 30px;
      display: block;
      width: 36px;
      height: 40px;
      overflow: hidden;
      pointer-events: none;
      left: 30px;

      @include centering(transform-y);
    }

    &::after {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-arrow.svg);
      background-size: contain;
      display: block;
      width: 10px;
      height: 8px;
      overflow: hidden;
      pointer-events: none;
      right: 0;

      @include centering(transform-y);
    }
  }

  &__arrow {
    position: relative;
    width: 30px;
    height: 100px;
    overflow: hidden;

    &::before {
      content: '';
      width: 32px;
      height: 106px;
      display: block;
      background: url("../images/common/c-line-black.svg") center right no-repeat;
      background-size: cover;
      right: 0;

      @include centering(transform-y);
    }
  }
}

@include media-breakpoint-down(sm) {
  .c-ai-route-planner {
    select {
      padding: 0 0 0 24px;
      font-size: $font-size-base-sp * 0.769230769230769; // 10px
    }

    &__from {
      margin-left: 8px;

      &::before {
        background: url(../images/common/c-icon-from.svg) center center no-repeat;
        background-size: 13px 18px;
        width: 18px;
        height: 18px;
        left: 0px;
      }

      &::after {
        width: 5px;
        height: 4px;
      }
    }

    &__duration {
      &::before {
        background: url(../images/common/c-icon-duration.svg) center center no-repeat;
        background-size: 16px 17px;
        width: 18px;
        height: 18px;
        left: 0px;
      }

      &::after {
        width: 5px;
        height: 4px;
      }
    }

    &__feeling {
      margin-right: 8px;

      &::before {
        background: url(../images/common/c-icon-feeling.svg) center center no-repeat;
        background-size: 15px 14px;
        width: 18px;
        height: 18px;
        left: 0px;
      }

      &::after {
        width: 5px;
        height: 4px;
      }
    }

    &__button {
      text-align: center;
      background: colors("yellow");
    }

    &__arrow {
      position: relative;
      width: 15px;
      height: 50px;
      overflow: hidden;

      &::before {
        content: '';
        width: 17px;
        height: 53px;
        display: block;
        background: url("../images/common/c-line-gray.svg") center right no-repeat;
        background-size: cover;
        right: 0;

        @include centering(transform-y);
      }
    }
  }
}


.home-why-kesennuma {
  min-height: 665px;
  height: auto;

  .is-open {
    display: none;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: url(../images/home/whygokesennuma-bg.png) no-repeat bottom right;
    background-size: 408px 160px;
    position: absolute;
    top: 0;
  }
}

@include media-breakpoint-down(sm) {
  .home-why-kesennuma {
    min-height: auto;
    padding-bottom: 5rem;

    &::before {
      background-size: 130px 55px;
    }
  }
}


.home-highlights {
  padding-top: 8rem;
}

@include media-breakpoint-down(sm) {
  .home-highlights {
    padding-top: 5rem;
  }
}


.home-see-do {
  padding-top: 20rem;
}

@include media-breakpoint-down(sm) {
  .home-see-do {
    padding-top: 10rem;
  }
}


.home-plan-your-trip {
  //padding-top: 14rem; 背景追加により削除
}

@include media-breakpoint-up(md) {
  .home-plan-your-trip {
    &__transform {
      padding-bottom: 80px;

      @include centering();
    }
  }
}

@include media-breakpoint-down(sm) {
  .home-plan-your-trip {
    //padding-top: 5rem; 背景追加により削除

    &__transform {
      top: 25px;

      @include centering(transform-x);
    }
  }
}

@include media-breakpoint-up(md) {
  .p-ai-route-planner {
    position: absolute;
    width: 100%;
    bottom: 0;

    &__ele {
      padding: 2.5rem 0;

      &--mod {
        line-height: 60px;

        &-link {
          padding-left: 64px;
          margin-right: 20px+60px+60px;
          background-position: center left;
          line-height: 60px;
          width: auto;
        }
      }

      &--mobile {
        background: none;
      }
    }

    &:hover {
      .c-link-arrow-adjacent--right::before {
        width: 120px;
        right: -120px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .p-ai-route-planner {
    position: absolute;
    width: 100%;
    bottom: 0;

    &__ele {
      padding: 1.5rem 2rem 1.5rem 8rem;

      &--mod {
        &-link {
          background: none;
        }
      }

      &--mobile {
        background-size: 30px 36px;
        background-position: center left 2rem;
      }
    }

    &:active {
      .c-link-arrow-flex::before {
        width: calc(100% + 60px);
      }
    }
  }
}


.home-category {
  margin-top: -3rem;
  padding-top: 10rem;
  padding-bottom: 14rem;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: url(../images/home/category-bg.png) no-repeat top 80px right;
    background-size: 408px 160px;
    position: absolute;
    top: 0;
  }

  .c-link-arrow {
    width: calc(100% - 140px);
  }
}

@include media-breakpoint-down(sm) {
  .home-category {
    padding-top: 5rem;
    padding-bottom: 5rem;

    &::before {
      background: url(../images/home/category-bg.png) no-repeat top 50px right;
      background-size: 204px 80px;
      right: -74px;
    }

    .c-link-arrow {
      margin-left: 3rem;
      padding-left: 1rem;
      width: calc(100% - 40px);

      &::before {
        left: -30px;
      }

      &-adjacent {
        &::before {
          width: 30px !important;
        }

        &--left:active::before {
          padding-right: 0;
        }
      }

      &--arrow-black {
        &::before {
          background-size: auto 8.5px;
        }
      }
    }

    .p-card {
      .p-card--hover .c-link-arrow.c-link-arrow__adjacent::before {
        width: 30px;
      }

      &:active .p-card--hover .c-link-arrow.c-link-arrow__adjacent::before {
        width: 30px;
      }
    }
  }
}


.home-map {
  &__display {
    width: 100%;
    height: 804px;
  }

  &__link {
    padding-left: 50rem;

    @include centering();

    .c-link-arrow.c-link-arrow__flex {
      padding-bottom: 0;
      width: max-content;
    }
  }
}

@include media-breakpoint-down(sm) {
  .home-map {
    &__display {
      height: 500px;
    }

    &__link {
      width: 40%;
      padding-left: 0;
      padding-bottom: 0rem;
      right: 0;
      left: unset;

      @include centering(transform-y);

      .c-link-arrow.c-link-arrow__flex {
        padding-bottom: 0;
        max-width: 100%;

        &::before {
          width: calc(100% - 50%);
        }
      }
    }
  }
}

.home-visitkesennuma {
  padding-top: 10rem;
  padding-bottom: 10rem;

  &__inner {
    width: 600px;
    margin: auto;
  }
}

@include media-breakpoint-down(sm) {
  .home-visitkesennuma {
    padding-top: 10rem;
    padding-bottom: 5rem;

    &__inner {
      width: calc(100% - 3rem);
      margin: auto;
    }
  }
}

.home-crewcardapp {
  &__inner {
    width: 920px;
    margin: auto;
    padding-bottom: 10rem;
  }
}

@include media-breakpoint-down(sm) {
  .home-crewcardapp {
    &__inner {
      width: calc(100% - 3rem);
      margin: auto;
    }
  }
}

// 2020.3.2 added by smmt
.aic-top-no-item-msg {
  font-size: 12px;
  color: #fff;
  max-width: 80%;
}

// ---------------------------------
// .top-movies
// ---------------------------------

.top-movies-item {
  &::before {
    content: none;
  }

  &__icon {
    background-color: colors("white");
    border-radius: 50%;
    color: colors("black");
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 2.5rem;
  }
}


// [note] 2020.12.19 Add for why kesennuma slideshow in modal.
.top-about-slideshow {
  &__pics {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  // &__pic {}

  // &__navs {}

  &__nav-prev,
  &__nav-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-color: colors('white');
    background-size: 10px 8px;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    top: 50%;
    transform: translateX(-50%);
  }

  &__nav-prev.disabled,
  &__nav-next.disabled {
    opacity: 0.5;
  }

  &__nav-next {
    position: absolute;
    right: 15px !important;
    transform: rotate(-90deg);
    background-image: url(../images/common/c-icon-arrow.svg);
  }

  &__nav-prev {
    position: absolute;
    left: 15px !important;
    transform: rotate(90deg);
    background-image: url(../images/common/c-icon-arrow.svg);
  }
}
