// ========================================
// header
// ========================================
// modaal用にz-indexの値を999に設定
.Header {
  background: rgba($header-bg-color, 1.0);
  position: fixed;
  z-index: 999;
  height: 80px;
  width: 100%;
  padding: 1rem 0;

  // 検索アイコン 元ファイルは parts-primitive/_icon 参照
  // ------------------------------
  .c-icon--search {
    width: 20px;
    height: 20px;
    display: block;
  }

  // メニューアイコン 元ファイルは parts-primitive/_icon 参照
  // ------------------------------
  .c-icon--menu {
    width: 20px;
    height: 20px;
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  .Header {
    height: 50px;
  }
}


// ---------------------------------
// Project
// ---------------------------------
.p-header {
  &-inner {
    background: transparent;
    min-width: 1080px;
    max-width: 1276px;
    width: 100%;
    height: 60px;
    @include media-breakpoint-only(md) {
      min-width: unset;
    }
  }
}

// selectのリセット
// ------------------------------
.p-select-reset {
  position: relative;

  select {
    color: inherit !important;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: none transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // for IE
    &::-ms-expand {
      display: none;
    }

    // for Firefox
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 white;
    }

    &:focus {
      box-shadow: none;
      background: none transparent;
    }
  }

  &.__header {
    select {
      padding: 0 14px 0 24px;
      width: auto;
    }

    &::before {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-head-lang.svg);
      background-size: contain;
      display: block;
      width: 20px;
      height: 20px;
      overflow: hidden;
      pointer-events: none;
      left: 0;

      @include centering(transform-y);
    }

    &::after {
      z-index: 1;
      content: "";
      background: url(../images/common/c-icon-arrow.svg);
      background-size: contain;
      display: block;
      width: 10px;
      height: 8px;
      overflow: hidden;
      pointer-events: none;
      right: 0;

      @include centering(transform-y);
    }
  }
}

@include media-breakpoint-down(sm) {
  .p-header {
    &-inner {
      min-width: unset;
      max-width: unset;
      height: 30px;
    }

    &-brand img {
      height: 100%;
    }
  }
}


// ---------------------------------
// Component
// ---------------------------------
// fontSize
.c-header-font-size {
  font-size: $font-size-base * 0.882352941176471; // 15px
}

.c-header-font-size-sm {
  font-size: $font-size-base * 0.882352941176471; // 15px
}

@include media-breakpoint-down(sm) {
  .c-header-font-size {
    font-size: $font-size-base-sp * 1.153846153846154; // 15px
  }

  .c-header-font-size-sm {
    font-size: $font-size-base-sp; // 13px
  }
}


// ---------------------------------
// Utility
// ---------------------------------


// 解像度375px以下の場合、ロゴを維持するためのハック（iPhoneSE:320px までは最低保つ）
// ------------------------------
@media screen and (max-width: 374px) {
  .Header {
    .p-header-brand {
      line-height: 30px;

      img {
        vertical-align: middle;
      }
    }
  }
}


// 解像度375px以下の場合、Bootstrapの挙動に戻す
// ------------------------------
@media screen and (max-width: 374px) {
  .p-select-reset.__header {
    select {
      //width: 100%;
      width: 0;
    }
  }
}

.dropdown-menu {
  display: block;
  background-color: rgba($header-bg-color, 1);
  border: 0;
  border-radius: 0;
  @include media-breakpoint-down(md) {
    position: relative;
    background-color: transparent;
    margin-left: $modaal-padding / 2 * -1;
    margin-right: $modaal-padding / 2 * -1;
  }
}

.collapse-toggle-hover {
  .collapse-toggle {
    color: $black;
    position: relative;
    @include media-breakpoint-down(md) {
      color: $white;
      padding-top: $dropdown-item-padding-y;
      padding-bottom: $dropdown-item-padding-y;
      display: block;
      border-bottom: 1px solid;
      position: relative;
      margin-left: $modaal-padding / 2 * -1;
      margin-right: $modaal-padding / 2 * -1;
      padding-left: $modaal-padding / 2;
      padding-right: $modaal-padding / 2;
    }
    .collapse-toggle-trigger {
      display: none;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 100%;
        display: inline-block;
        background-color: transparent;
        user-select: none;
        filter: brightness(0) invert(1);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        // [Plus Icon | Font Awesome](https://fontawesome.com/icons/plus?style=solid)
        background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJwbHVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtcGx1cyBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQxNiAyMDhIMjcyVjY0YzAtMTcuNjctMTQuMzMtMzItMzItMzJoLTMyYy0xNy42NyAwLTMyIDE0LjMzLTMyIDMydjE0NEgzMmMtMTcuNjcgMC0zMiAxNC4zMy0zMiAzMnYzMmMwIDE3LjY3IDE0LjMzIDMyIDMyIDMyaDE0NHYxNDRjMCAxNy42NyAxNC4zMyAzMiAzMiAzMmgzMmMxNy42NyAwIDMyLTE0LjMzIDMyLTMyVjMwNGgxNDRjMTcuNjcgMCAzMi0xNC4zMyAzMi0zMnYtMzJjMC0xNy42Ny0xNC4zMy0zMi0zMi0zMnoiPjwvcGF0aD48L3N2Zz4=');
        &:not([aria-expanded="false"]) {
          // [Minus Icon | Font Awesome](https://fontawesome.com/icons/minus?style=solid)
          background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJtaW51cyIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLW1pbnVzIGZhLXctMTQiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDQ4IDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNNDE2IDIwOEgzMmMtMTcuNjcgMC0zMiAxNC4zMy0zMiAzMnYzMmMwIDE3LjY3IDE0LjMzIDMyIDMyIDMyaDM4NGMxNy42NyAwIDMyLTE0LjMzIDMyLTMydi0zMmMwLTE3LjY3LTE0LjMzLTMyLTMyLTMyeiI+PC9wYXRoPjwvc3ZnPg==');
        }
      }
    }
  }
  .dropdown-item {
    text-decoration: none;
    color: $dark;
    transition: color 1s;
    @include media-breakpoint-down(md) {
      color: $white;
      border-bottom: 1px solid;
      position: relative;
      padding-left: $modaal-padding;
      padding-right: $modaal-padding;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 100%;
        filter: brightness(0) invert(1);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 45%;
        // [Font Awesome](https://fontawesome.com/icons/angle-right?style=solid)
        background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJhbmdsZS1yaWdodCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFuZ2xlLXJpZ2h0IGZhLXctOCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0yMjQuMyAyNzNsLTEzNiAxMzZjLTkuNCA5LjQtMjQuNiA5LjQtMzMuOSAwbC0yMi42LTIyLjZjLTkuNC05LjQtOS40LTI0LjYgMC0zMy45bDk2LjQtOTYuNC05Ni40LTk2LjRjLTkuNC05LjQtOS40LTI0LjYgMC0zMy45TDU0LjMgMTAzYzkuNC05LjQgMjQuNi05LjQgMzMuOSAwbDEzNiAxMzZjOS41IDkuNCA5LjUgMjQuNi4xIDM0eiI+PC9wYXRoPjwvc3ZnPg==');
      }
    }
    &:hover {
      color: $black;
      opacity: 0.6;
      transition: all 0.3s;

      @include media-breakpoint-down(md) {
        color: $white;
      }
    }
  }
}

.collapse-toggle-trim-mobile {
  .collapse-toggle {
      @include media-breakpoint-down(md) { 
        display: none;
    }
  }
  .dropdown-menu {
    @include media-breakpoint-down(md) { 
      display: block !important;
    }
  }
  .dropdown-item {
    @include media-breakpoint-down(md) { 
      padding-left: $modaal-padding / 2;
      padding-right: $modaal-padding / 2;
    }
  }
}

// ---------------------------------
// header-lang
// ---------------------------------

.header-lang {
  &__select {
    max-width: 120px;
  }
}
