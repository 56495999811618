// ========================================
// _mixins.scss
// ========================================

// ---------------------------------
// Layout
// ---------------------------------

// col-space
// ------------------------------
@mixin col-space($m: 0.75) {
  $spacer: $spacer * $m;

  & > [class*="row"] {
    margin-right: -($spacer * $m);
    margin-left: -($spacer * $m);

    @include media-breakpoint-up(lg) {
      margin-right: -$spacer;
      margin-left: -$spacer;
    }

    & > [class*="col"] {
      padding-left: $spacer * $m;
      padding-right: $spacer * $m;

      @include media-breakpoint-up(lg) {
        padding-left: $spacer;
        padding-right: $spacer;
      }
    }
  }
}


// ---------------------------------
// Effect - Animation
// ---------------------------------

// cmn-transision
// ------------------------------
@mixin cmn-transision {
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: color, background-color, width, height, opacity, border-color, border;
  transition-timing-function: ease;
}


// ---------------------------------
// Effect - state
// ---------------------------------

// fx-blur
// ------------------------------
@mixin fx-blur() {
  position: relative;
  overflow: hidden;
  z-index: 2;

  &::before {
    // posisionはブラー効果で画像の端がボヤけた分だけ位置を調整
    background: inherit;
    bottom: -5px;
    content: '';
    filter: blur(5px);
    left: -5px;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1; // 重なり順必須
  }
}


// ---------------------------------
// Posisioning
// ---------------------------------

// centering
// ------------------------------
@mixin centering($type: transform) {
  @if $type == position {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  } @else if $type == position-x {
    position: absolute;
    top: auto;
    right: 0;
    bottom: auto;
    left: 0;
    margin: auto;
    text-align: center; // [note] For block elm
  } @else if $type == position-y {
    // [note] you need to set height on base style
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;
    margin: auto;
  } @else if $type == transform-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  } @else if $type == transform-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  } @else {
    // [note] aka transform
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}


// ---------------------------------
// Background Posisioning
// ---------------------------------

// cover-fix-bg
// ------------------------------
@mixin cover-fix-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
}

// cover-bg
// ------------------------------
@mixin cover-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

// contain-bg
// ------------------------------
@mixin contain-bg($width:1000, $height:500) {
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  padding-top: $height / $width * 100%;
  background-position: top center;
}

// contain
@mixin contain($width, $height, $image: null) {
  display: block;
  height: 0;
  padding-top: ($height / $width) * 100%;
  @if $image != null {
    background: url($image) 50% 50% no-repeat;
    background-size: cover;
  }
}


// ---------------------------------
// Reset & Module
// ---------------------------------

// Reset list Style
// ------------------------------
@mixin reset-list-style {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

// Set link color
// ------------------------------
@mixin set-link-color($c1: $body-color, $c2: $body-color) {
  color: $c1;

  &:hover,
  &:focus {
    color: $c2;
  }
}

// Add Clearfix
// ------------------------------
@mixin clearfix() {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}


// ---------------------------------
// Add
// ---------------------------------

// Mediaquery
// ------------------------------
//@mixin max($break-point) {
//  @media screen and (max-width: $break-point) {
//    @content;
//  }
//}
//@mixin min($break-point) {
//  @media screen and (min-width: $break-point + 1) {
//    @content;
//  }
//}
//@mixin screen($break-point-min, $break-point-max) {
//  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
//    @content;
//  }
//}

// tracking:
// ------------------------------
@mixin tracking($tracking) {
  letter-spacing: ($tracking / 1000 ) + em;
}

// text-hidden:
// ------------------------------
@mixin text-hidden {
  overflow: hidden;
  text-indent: 9999em;
  white-space: nowrap;
}

// full-width:
// ------------------------------
//@mixin w-full{
//
//}

// Blocks that maintain aspect ratio
// ------------------------------
@mixin aspect-ratio($width, $height, $image: null) {
  display: block;
  //width: 100%;
  height: 0;
  padding-top: ($height / $width) * 100%;
  @if $image != null {
    background: url($image) 50% 50% no-repeat;
    background-size: cover;
  }
}