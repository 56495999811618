// ========================================
// main
// ========================================

// ---------------------------------
// Main container
// ---------------------------------

// ヘッダー固定の分、コンテンツを下げる
.Main {
  padding-top: 13rem;
  position: relative;
  z-index: 0;
  display: block;

  &--type_top { padding-top: 8rem; }

  &__column {
    width: 918px;
    min-width: 918px;
    height: 100%;
    margin: auto;
    padding-top: 30px;
  } // 下層 2カラム

  &__breadcrumbs {
    padding-bottom: 2.5rem;
    margin-bottom: 6rem;
  }
}

@include media-breakpoint-down(sm) {
  .Main {
    padding-top: 5rem;
    overflow-x: hidden;

    &--type_top { padding-top: 0; }

    &__column {
      min-width: auto;
      width: calc(100% - 3rem);
      height: auto;
      min-height: auto;
      padding-bottom: 5.5rem;
    }

    &__breadcrumbs {
      padding-bottom: 2.5rem;
      padding-top: 5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      margin-bottom: 6rem;
    }
  }
}


// 下層 2カラム サイドバー
// ---------------------------------
// .Main__colum と対に使用する
.Sidebar {
  min-width: 317px;
  max-width: 317px;
  top: 200px;

  &__inner {
    background: colors("white");

    .w-define {
      min-width: 100%;
      max-width: 100%;
      padding: 3rem;

      &:nth-child(even) {
        background: colors("alpha-blue-10");
      }
    }

    .p-card {
      position: relative;
      z-index: 0;

      // fix-number
      &__num {
        position: absolute;
        top: -6px;
        left: -12px;
        width: 38px;
        height: 38px;
        line-height: 38px;
        display: block;
        z-index: 2;
        text-align: center;
        font-size: $font-size-base;
        font-weight: 600;
        color: colors("black");

        &::before {
          content: '';
          width: 38px;
          height: 38px;
          display: block;
          background: url(../images/common/c-icon-polygon-aside.svg) no-repeat;
          background-size: contain;
          z-index: -1;

          @include centering();
        }
      }
    }
  }

  &__contents-banner-wrap {
    height: 212px;
  }
}

@include media-breakpoint-down(sm) {
  .Sidebar {
    min-width: 100%;
    max-width: 100%;
    top: 0;
    width: 100%;

    &__inner {
      background: colors("white");
    }
  }
}


// ---------------------------------
// Inner parts
// ---------------------------------
