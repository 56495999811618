// ========================================
// Movie
// ========================================

.movie {
  .btn.share {
    width: 38px;
    height: 38px;
  }
}

.movies-item {
  &::before {
    content: none;
  }

  &__pic {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  &__icon {
    background-color: colors("white");
    border-radius: 50%;
    color: colors("black");
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 2.5rem;
  }
}
