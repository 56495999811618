// ========================================
// Access
// ========================================
.access {
  &--background {
    background: $color-blue-access;
  }

  &--hr {
    border-top: 1px solid $color-blue-access;
  }
}
