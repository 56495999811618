// ========================================
// Project - myplan
// ========================================
.p-myplan-layout {
  position: fixed;
  top: 9rem;
  right: 0;
  z-index: 10;
}

// ヘッダー分+レイアウト


@include media-breakpoint-down(sm) {
  .p-myplan-layout {
    top: 6rem;
  }
}
