// ---------------------------------
// Component:Link
// ---------------------------------
$link-arrow-padding-bottom: 4rem;
$link-author-arrow-padding-top: 2rem;
$link-author-arrow-padding-bottom: 2rem;

// 追従 My Plan専用リンク
// ------------------------------
.p-link-myplan {
  background: colors("black");

  @extend .text-decoration-none;
  @extend .font-weight-bold;

  &__base {
    background: colors("black");

    &--text {
      color: colors("white");
      display: block;
      position: relative;
      padding-right: 34px;
      padding-left: 30px + 10px + 10px;
      min-height: 30px + 20px;
      line-height: 30px + 20px;

      @include transition();

      &::before {
        content: '';
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 50%;
        border: 3px solid colors("white");
        box-sizing: border-box;
        background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
        background-size: 11px auto;
        background-color: colors("white");
        position: absolute;
        left: 10px;

        @include centering(transform-y);
      }

      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("yellow");
          color: colors("black");

          &::before {
            background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
            background-size: 11px auto;
            background-color: colors("white");
          }
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("yellow");
          color: colors("black");

          &::before {
            background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
            background-size: 11px auto;
            background-color: colors("white");
          }
        }
      }
    }

    &--num {
      background: colors("yellow");
      color: colors("black");
      min-height: 30px + 20px;
      line-height: 30px + 20px;
      padding: 0 8px;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -12px;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        border: 10px solid transparent;
        border-right: 15px solid colors("yellow");
        border-left-width: 0;

        @include centering(transform-y);
      }
    }
  }
}


// Header：マウスオーバー
// ------------------------------
.c-link-head {
  position: relative;

  &::before {
    content: '';
    width: 30px;
    height: 5px;
    display: block;
    background: url(../images/common/c-link-head--hover.svg) no-repeat;
    bottom: -8px;
    opacity: 0;

    @include centering(transform-x);
    @include transition();
    @include media-breakpoint-down(md) {
      content: none;
    }
  }

  &.is-active {
    // color: $blue;
    cursor: default;

    &:hover {
      &::before {
        opacity: 0;
      }
    }

    &:active {
      &::before {
        opacity: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      color: $white;
    }
  }

  @include media-breakpoint-up(md) {
    color: $white;

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    color: $white;

    &:active {
      &::before {
        opacity: 1;
      }
    }
  }
}


// 汎用：透過リンク
// ------------------------------
// <a class="c-link-hover" href="#"> ~ </a>
// todo:透明度の値指示なし
.c-link-hover {
  opacity: 1;
  cursor: pointer;

  @include transition();

  @include media-breakpoint-up(md) {
    &:hover {
      opacity: 0.8;
    }
  }

  @include media-breakpoint-down(sm) {
    &:active {
      opacity: 0.8;
    }
  }
}


// 汎用：アローリンク
// ------------------------------
.c-link-arrow {
  position: relative;
  display: inline-block;

  @extend .text-decoration-none;
  @extend .font-weight-bold;

  // 矢印の色
  // ------------------------------
  &--arrow-white {
    &::before {
      background: url(../images/common/c-arrow-white-right.png) no-repeat bottom right;
      background-size: auto 12px;
    }

    &-re {
      &::before {
        background: url(../images/common/c-arrow-white-left.png) no-repeat bottom left;
        background-size: auto 12px;
      }
    }
  }

  &--arrow-black {
    &::before {
      background: url(../images/common/c-arrow-black-right.png) no-repeat bottom right;
      background-size: auto 12px;
    }

    &-re {
      &::before {
        background: url(../images/common/c-arrow-black-left.png) no-repeat bottom left;
        background-size: auto 12px;
      }
    }
  }

  &--arrow-blue {
    &::before {
      background: url(../images/common/c-arrow-blue-right.png) no-repeat bottom right;
      background-size: auto 12px;
    }

    &-re {
      &::before {
        background: url(../images/common/c-arrow-blue-left.png) no-repeat bottom left;
        background-size: auto 12px;
      }
    }
  }

  // hover時に+60px拡大されるスタイル
  &-flex {
    max-width: calc(100% - 60px);
    padding-right: 3rem;
    padding-bottom: $link-arrow-padding-bottom;

    &::before {
      content: '';
      width: 100%;
      height: 12px;
      display: block;
      position: absolute;
      bottom: 0;
      //transition: all 0.2s ease-in-out;


      @include transition();
    }

    @include media-breakpoint-up(md) {
      &:hover {
        &::before {
          width: 120%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &:active {
        &::before {
          width: calc(100% + 60px);
        }
      }
    }
  }

  // 幅固定のスタイル
  &-fixed {
    padding-bottom: $link-arrow-padding-bottom;

    &::before {
      content: '';
      width: 60px;
      height: 12px;
      display: block;
      position: absolute;
      bottom: 0;

      @include transition();
    }

    @include media-breakpoint-up(md) {
      &:hover {
        &::before {
          width: 120px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &:active {
        &::before {
          width: 120px;
        }
      }
    }

    // アローの配置
    // ------------------------------
    &--left {
      width: 100%;
      padding-left: 12rem + 2rem;
      padding-bottom: 0;

      &::before {
        left: 0;

        @include centering(transform-y);
      }
    }

    &--right {
      text-align: left;
      width: calc(100% - 120px);
      padding-right: 2rem;
      padding-bottom: 0;

      &::before {
        right: -60px;

        @include centering(transform-y);
      }

      @include media-breakpoint-up(md) {
        &:hover {
          &::before {
            width: 120px;
            right: -120px;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          &::before {
            width: 120px;
            right: -120px;
          }
        }
      }
    }
  }

  // 隣接のスタイル
  &-adjacent {
    &::before {
      content: '';
      width: 60px;
      height: 12px;
      display: block;
      position: absolute;

      @include transition();
    }

    // アローの配置
    &--left {
      margin-left: 12rem;
      padding-left: 2rem;

      &::before {
        left: -120px;
        padding-ight: 0px;

        @include centering(transform-y);
      }

      @include media-breakpoint-up(md) {
        &:hover {
          &::before {
            padding-right: 120px;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          &::before {
            padding-right: 120px;
          }
        }
      }

      // 逆向きのパターン追加
      &-re {
        margin-left: 12rem;
        padding-left: 2rem;

        &::before {
          left: -60px;

          @include centering(transform-y);
        }

        @include media-breakpoint-up(md) {
          &:hover {
            &::before {
              width: 120px;
              left: -120px;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          &:active {
            &::before {
              width: 120px;
              left: -120px;
            }
          }
        }
      }
    }

    &--right {
      margin-right: 12rem;
      padding-right: 2rem;

      &::before {
        right: -60px;
        padding-right: 0px;

        @include centering(transform-y);
      }

      @include media-breakpoint-up(md) {
        &:hover {
          &::before {
            width: 120px;
            right: -120px;

          }
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          &::before {
            width: 120px;
            right: -120px;
          }
        }
      }

      &-re {
        margin-right: 12rem;
        padding-right: 2rem;

        &::before {
          right: -120px;

          @include centering(transform-y);
        }

        @include media-breakpoint-up(md) {
          &:hover {
            &::before {
              width: 120px;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          &:active {
            &::before {
              width: 120px;
            }
          }
        }
      }
    }
  }
}


// 汎用：Planリンク
// ------------------------------
.c-link-plan {
  background: colors("alpha-black-60");
  color: colors("white");
  display: block;
  position: relative;
  padding: 20px 20px 20px (40px + 10px + 20px);
  border: none;
  outline: none !important;

  // buttonに設定した際の中央寄せになる問題のため設置
  text-align: left;

  @include transition();
  @extend .text-decoration-none;
  @extend .font-weight-bold;

  &::before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    border: 3px solid colors("white");
    box-sizing: border-box;
    background: url(../images/common/c-icon-check-white.svg) no-repeat center center;
    background-color: transparent;
    position: absolute;
    left: 20px;

    @include centering(transform-y);
  }

  &.is-active {
    background: colors("yellow");
    color: colors("black");

    &::before {
      background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
      background-color: colors("white");
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      background: colors("black");
      color: colors("white");
    }
    &:active {
      background: colors("yellow");
      color: colors("black");

      &::before {
        background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
        background-color: colors("white");
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &:active {
      background: colors("yellow");
      color: colors("black");

      &::before {
        background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
        background-color: colors("white");
      }
    }
  }
}

// .plan-your-trip Dedicated class
.c-link-plan-right {
  color: colors("white");
  display: inline-block;
  position: relative;
  padding: 20px 20px 20px (40px + 10px + 20px);

  @include transition();
  @extend .text-decoration-none;
  @extend .font-weight-bold;

  &::before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    border: 3px solid colors("white");
    box-sizing: border-box;
    background: url(../images/common/c-icon-check-white.svg) no-repeat center center;
    background-color: transparent;
    position: absolute;
    left: 20px;

    @include centering(transform-y);
  }
}


// 汎用：ボーダーリンク
// ------------------------------
.c-link-border {
  border: 3px solid colors("white");
  box-sizing: border-box;
  display: inline-block;
  padding: 2rem 4rem 2rem 2rem;
  position: relative;

  @include transition();
  @extend .text-decoration-none;
  @extend .font-weight-bold;

  &::before {
    content: '';
    width: 10px;
    height: 8px;
    display: block;
    background: url(../images/common/c-icon-arrow-w.svg);
    background-size: cover;
    right: 20px;

    @include centering(transform-y);
  }

  @include media-breakpoint-up(md) {
    &:hover {
      color: colors("blue") !important;
      background: colors("white");


      &::before {
        background: url(../images/common/c-icon-arrow-bl.svg);
        background-size: cover;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 1.25rem 4rem 1.25rem 2rem;

    &:active {
      color: colors("blue") !important;
      background: colors("white");


      &::before {
        mask-image: url(../images/common/c-icon-arrow.svg);
        background: colors("blue");
      }
    }
  }
}


// 汎用：ビデオリンク
// ------------------------------
.c-link-video {
  position: relative;
  z-index: 0;
  display: block;
  cursor: pointer;

  &::before {
    content: "";
    width: 104px;
    height: 104px;
    display: block;
    background: url(../images/common/c-icon-arrow.svg) no-repeat center center colors("white");
    background-size: 40px 32px;
    border-radius: 50%;
    right: -15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(-90deg);
    z-index: 1;
  }

  &--mini {
    &::before {
      content: "";
      width: 60px;
      height: 60px;
      display: block;
      background: url(../images/common/c-icon-arrow.svg) no-repeat center center colors("white");
      background-size: 23px 18px;
      border-radius: 50%;
      right: -15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(-90deg);
      z-index: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    &::before {
      content: "";
      width: 52px;
      height: 52px;
      background-size: 20px 16px;
    }
  }
}


// 汎用：外部リンク
// ------------------------------
.c-link-external {
  position: relative;
  z-index: 0;
  display: block;
  cursor: pointer;

  &::before {
    content: "";
    width: 104px;
    height: 104px;
    display: block;
    background: url(../images/common/c-icon-external.png) no-repeat center center colors("white");
    background-size: 40px auto;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    font-size: 50px;
  }

  &--mini {
    &::before {
      content: "";
      width: 60px;
      height: 60px;
      display: block;
      background: url(../images/common/c-icon-external.png) no-repeat center center colors("white");
      background-size: 25px auto;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 1;
    }
  }
}


// 汎用：PDFリンク
// ------------------------------
.c-link-pdf {
  position: relative;
  z-index: 0;
  display: block;
  cursor: pointer;

  &::before {
    content: "";
    width: 104px;
    height: 104px;
    display: block;
    background: url(../images/common/c-icon-pdf.png) no-repeat center center colors("white");
    background-size: 40px auto;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    font-size: 50px;
  }

  &--mini {
    &::before {
      content: "";
      width: 60px;
      height: 60px;
      display: block;
      background: url(../images/common/c-icon-pdf.png) no-repeat center center colors("white");
      background-size: 32px auto;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 1;
    }
  }
}


// 汎用：ボックスリンク
// 背景色をダイレクトに切り替える、切り替えたくない要素には予め色指定して回避する
// ------------------------------
// ex .c-link-color-box .__before-black .--after-yellow : ベースクラスと前後切り替えたいカラークラスをセットで指定する
.c-link-color-box {
  display: block;

  @include transition();
  @extend .text-decoration-none;

  &__white {
    background: colors("white");
    color: colors("black");

    &--black {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("black");
          color: colors("white");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("black");
          color: colors("white");
        }
      }
    }

    &---blue {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("blue");
          color: colors("white");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("blue");
          color: colors("white");
        }
      }
    }

    &--yellow {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("yellow");
          color: colors("black");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("yellow");
          color: colors("black");
        }
      }
    }
  }

  &__black {
    background: colors("black");
    color: colors("white");

    &--white {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("white");
          color: colors("black");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("white");
          color: colors("black");
        }
      }
    }

    &--blue {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("blue");
          color: colors("white");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("blue");
          color: colors("white");
        }
      }
    }

    &--yellow {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("yellow");
          color: colors("black");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("yellow");
          color: colors("black");
        }
      }
    }
  }

  &__blue {
    background: colors("blue");
    color: colors("white");

    &--black {
      &:hover,
      &:active {
        background: colors("black");
        color: colors("white");
      }
    }

    &--white {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("white");
          color: colors("black");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("white");
          color: colors("black");
        }
      }
    }

    &--yellow {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("yellow");
          color: colors("black");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("yellow");
          color: colors("black");
        }
      }
    }
  }

  &__yellow {
    background: colors("yellow");
    color: colors("black");

    &--black {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("black");
          color: colors("white");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("black");
          color: colors("white");
        }
      }
    }

    &--white {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("white");
          color: colors("black");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("white");
          color: colors("black");
        }
      }
    }

    &--blue {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("blue");
          color: colors("white");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("blue");
          color: colors("white");
        }
      }
    }
  }

  &__black-alpha {
    &-60 {
      background: colors("alpha-black-60");
      color: colors("white");
    }

    &-80 {
      background: colors("alpha-black-80");
      color: colors("white");
    }

    &--black {
      @include media-breakpoint-up(md) {
        &:hover {
          background: colors("black");
          color: colors("white");
        }
      }

      @include media-breakpoint-down(sm) {
        &:active {
          background: colors("black");
          color: colors("white");
        }
      }
    }

    // .plan-your-trip Dedicated class
    &--active-yellow {
      &:active {
        background: colors("yellow");

        .c-link-plan-right {
          color: colors("black") !important;

          &::before {
            background: url(../images/common/c-icon-check-black.svg) no-repeat center center;
            background-color: white;
          }
        }
      }
    }
  }
}


// 汎用：円形リンク
// ------------------------------
.c-link-circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  font-weight: 600;
  text-decoration: none !important;
  border-radius: 50%;
  background: colors("white");
  color: colors("black");

  @include transition();

  &.c-link-circle_is-active,
  &:hover {
    background: colors("black");
    color: colors("white");
  }

  &__prev {
    position: relative;
    overflow: hidden;
    text-indent: 9999em;
    white-space: nowrap;

    &::before {
      content: "";
      background: url(../images/common/c-icon-arrow.svg);
      background-size: cover;
      display: block;
      width: 10px;
      height: 8px;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(90deg);
    }

    &.c-link-circle_is-active,
    &:hover {
      &::before {
        background: url(../images/common/c-icon-arrow-w.svg);
        background-size: cover;
      }
    }
  }

  &__next {
    position: relative;
    overflow: hidden;
    text-indent: 9999em;
    white-space: nowrap;

    &::before {
      content: "";
      background: url(../images/common/c-icon-arrow.svg);
      background-size: cover;
      display: block;
      width: 10px;
      height: 8px;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(-90deg);
    }

    &.--is-active,
    &:hover {
      &::before {
        background: url(../images/common/c-icon-arrow-w.svg);
        background-size: cover;
      }
    }
  }
}


// 汎用：インラインリンク
// ------------------------------
// 主にモバイル表示での破綻を防ぐクラス
.c-link-inline {
  display: inline-block;
}
@include media-breakpoint-down(sm) {
  .c-link-inline {
    display: block;
    text-align: center;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}


// 汎用：拡大
// ------------------------------
// .p-card-scale > .overflow-hidden > .p-card-scale__thumbnail
// todo:定義を別のファイルに移動予定
.p-card-scale {
  &__thumbnail {
    transform: scale(1);
    overflow: hidden;

    @include transition();
  }

  @include media-breakpoint-up(md) {
    &:hover {
      .p-card-scale__thumbnail {
        transform: scale(1.2);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &:active {
      .p-card-scale__thumbnail {
        transform: scale(1.2);
      }
    }
  }
}


// 汎用：複合アローリンク
// ------------------------------
// todo:定義を別のファイルに移動予定
.p-link-author {
  background-color: transparent;
  position: relative;

  @extend .text-decoration-none;
  @extend .font-weight-bold;

  &__thumbnail {
    display: block;
  }

  &__arrow {
    position: relative;
    display: block;
    padding: $link-author-arrow-padding-top 3rem $link-author-arrow-padding-bottom 0;
    margin-bottom: 0;

    &::before {
      content: '';
      width: 80%;
      height: 12px;
      display: block;
      background: url(../images/common/c-arrow-white-right.png) no-repeat bottom right;
      background-size: auto 12px;
      position: absolute;
      bottom: 0;

      @include transition();
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      .p-link-author__arrow {
        &::before {
          width: 98%;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &:active {
      .p-link-author__arrow {
        &::before {
          width: 98%;
        }
      }
    }
  }
}

.p-card--hover:hover .p-link-author {
  @include media-breakpoint-up(md) {
    .p-link-author__arrow {
      &::before {
        width: 98%;
      }
    }
  }
}


// 汎用：Card
// ------------------------------
// .p-card > .p-card__inner
// .p-card > .p-card__position-bottom : 要素下部に入れ込む要素に利用
// todo:定義を別のファイルに移動予定
.p-card {
  position: relative;
  z-index: 0;

  // Card inner
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  // fix-All directions
  &__position {
    @include centering();
  }

  // fix-number
  &__num {
    position: absolute;
    top: -30px;
    left: 14px;
    width: 61px;
    height: 60px;
    line-height: 60px;
    display: block;
    z-index: 2;
    text-align: center;
    font-size: ($font-size-base * 1.764705882352941);
    font-weight: 600;
    color: colors("black");

    &::before {
      content: '';
      width: 61px;
      height: 60px;
      display: block;
      background: url(../images/common/c-icon-polygon.svg) no-repeat;
      background-size: contain;
      z-index: -1;

      @include centering();
    }
  }

  //
  &__bg-black {
    background: colors("black");

    &--img-opacity-60 {
      opacity: 0.6;
    }

    &--img-opacity-80 {
      opacity: 0.8;
    }
  }

  &__bg-white {
    background: colors("white");
  }

  // Hover control
  @include media-breakpoint-up(md) {
    &:hover {
      .p-card__inner,
      .p-card--hover {
        .c-link-arrow {
          &.c-link-arrow-fixed::before {
            width: 120px;
          }

          &.c-link-arrow-flex::before {
            width: calc(100% + 60px);
          }

          &.c-link-arrow-adjacent.c-link-arrow-adjacent--right::before {
            width: 120px;
            right: -120px;
          }

          &.c-link-arrow-adjacent.c-link-arrow-adjacent--left::before {
            width: 120px;
          }

          &.c-link-arrow--to-black {
            &::before {
              background: url(../images/common/c-arrow-black-right.png) no-repeat bottom right;
              background-size: auto 12px;
            }
          }

          &.c-link-arrow--to-blue {
            &::before {
              background: url(../images/common/c-arrow-blue-right.png) no-repeat bottom right;
              background-size: auto 12px;
            }
          }

          &.c-link-arrow--to-white {
            &::before {
              background: url(../images/common/c-arrow-white-right.png) no-repeat bottom right;
              background-size: auto 12px;
            }
          }
        }

        .c-link-color-box {
          &.c-link-color-box__white {
            color: colors("white");
          }

          &.c-link-color-box__white--black {
            background: colors("black");

            .c-icon::before {
              background: colors("white");
            }
          }
        } // c-link-color-box

        .c-link-color-box__black-alpha--black {
          background: colors("black");
        } // c-link-color-box

        .c-icon {
          &--by-bicycle {
            &::before {
              background: url("../images/common/c-icon-by-bicycle-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-bus {
            &::before {
              background: url("../images/common/c-icon-by-bus-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-car {
            &::before {
              background: url("../images/common/c-icon-by-car-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-train {
            &::before {
              background: url("../images/common/c-icon-by-train-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-walk {
            &::before {
              background: url("../images/common/c-icon-by-walk-w.png") !important;
              background-size: cover !important;
            }
          }
        }
      }
    }
  }

  // Touch control
  @include media-breakpoint-down(sm) {
    &:active {
      .p-card__inner,
      .p-card--hover {
        .c-link-arrow {
          &.c-link-arrow-fixed::before {
            width: 120px;
          }

          &.c-link-arrow-flex::before {
            width: calc(100% + 60px);
          }

          &.c-link-arrow-adjacent.c-link-arrow-adjacent--right::before {
            width: 120px;
            right: -120px;
          }

          &.c-link-arrow-adjacent.c-link-arrow-adjacent--left::before {
            width: 120px;
          }

          &.c-link-arrow--to-black {
            &::before {
              background: url(../images/common/c-arrow-black-right.png) no-repeat bottom right;
              background-size: auto 12px;
            }
          }

          &.c-link-arrow--to-blue {
            &::before {
              background: url(../images/common/c-arrow-blue-right.png) no-repeat bottom right;
              background-size: auto 12px;
            }
          }

          &.c-link-arrow--to-white {
            &::before {
              background: url(../images/common/c-arrow-white-right.png) no-repeat bottom right;
              background-size: auto 12px;
            }
          }
        }

        .c-link-color-box {
          &.c-link-color-box__white {
            color: colors("white");
          }

          &.c-link-color-box__white--black {
            background: colors("black");

            .c-icon::before {
              background: colors("white");
            }
          }
        } // c-link-color-box

        .c-link-color-box__black-alpha--black {
          background: colors("black");
        } // c-link-color-box

        .c-icon {
          &--by-bicycle {
            &::before {
              background: url("../images/common/c-icon-by-bicycle-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-bus {
            &::before {
              background: url("../images/common/c-icon-by-bus-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-car {
            &::before {
              background: url("../images/common/c-icon-by-car-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-train {
            &::before {
              background: url("../images/common/c-icon-by-train-w.png") !important;
              background-size: cover !important;
            }
          }

          &--by-walk {
            &::before {
              background: url("../images/common/c-icon-by-walk-w.png") !important;
              background-size: cover !important;
            }
          }
        }
      }
    }
  }
}

//
.p-hover {
  // Hover control
  @include media-breakpoint-up(md) {
    &:hover {
      .c-link-arrow-adjacent--right::before {
        width: 120px;
        right: -120px;
      } // Use itinerary-1
    }
  }

  @include media-breakpoint-down(sm) {
    &:active {
      .c-link-arrow-adjacent--right::before {
        width: 120px;
        right: -120px;
      } // Use itinerary-1
    }
  }
}
