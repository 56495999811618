// ========================================
// wrap-video
// ========================================

.wrap-video {
  position: relative;

  @include aspect-ratio(560,315);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
