// ========================================
// Project - tab
// ========================================

.p-tab {
  &__button {
    background: transparent;
    color: colors("white");
    outline: none !important;
    position: relative;

    &.is-active {
      background: colors("white");
      color: colors("blue");

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 10px 0 10px;
        border-color: #ffffff transparent transparent transparent;
        bottom: -14px;

        @include centering(transform-x);
      }
    }
  }

  &__item {
    display: none;

    &.is-show {
      display: block;
    }
  }
}
