// [note]
// src/app/views/concierge-result/index.phpは
// 大半の部分を./_plan-your-trip.scssより参照

.aic-result-incorrectly-error-mgs-box-wrap {
  min-height: 450px;

  a {
    color: $blue;

    &:hover { color: darken($blue, 15%); }
  }
}

.aic-result-incorrectly-error-mgs-box {
  background-color: #eee;
  border: 1px dotted #aaa;
}

.aic-result-back-button {
  $_bg-color: #6fd17c;

  display: inline-block;
  padding: 1rem 1rem;
  background-color: $_bg-color;
  color: $white;
  text-decoration: none;

  &:hover {
    color: $white;
    background-color: darken($_bg-color, 15%);
    text-decoration: none;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.aic-result-course-copy-button {
  $_bg-color: #e89;

  display: inline-block;
  padding: 1rem 1rem;
  background-color: $_bg-color;
  color: $white;

  &:hover {
    color: $white;
    background-color: darken($_bg-color, 15%);
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
