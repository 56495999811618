// ========================================
// Theme
// ========================================
.theme {
  // Contents
  .c-link-arrow {
    position: relative;
    display: inline-block;

    @extend .text-decoration-none;
    @extend .font-weight-bold;

    &-longarrow {
      &::before {
        content: '';
        width: 80%;
        height: 12px;
        display: block;
        position: absolute;

        @include transition();
      }

      &--arrow-blue {
        &::before {
          background: url(../images/common/c-arrow-blue-right.png) no-repeat;
          background-position: bottom right;
          background-size: auto 12px;

          @include centering(transform-y);
        }
      }
    }
  }

  // shareButton
  .btn.share {
    width: 38px;
    height: 38px;
  }

  // hover override
  .p-card {
    @include media-breakpoint-up(md) {
      &:hover {
        // A.I.Route Planner hover override
        .p-card---hover .c-link-arrow-adjacent::before {
          //width: calc(60px + 60px);
          width: 120px;
          right: -120px;
        }

        // c-link-arrow-longarrow--arrow-blue hover override
        .p-card--hover .c-link-arrow-longarrow::before {
          //width: calc(100% - 0px);
          width: 100%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &:active {
        // A.I.Route Planner hover override
        .p-card---hover .c-link-arrow-adjacent::before {
          //width: calc(60px + 60px);
          width: 120px;
          right: -120px;
        }

        // c-link-arrow-longarrow--arrow-blue hover override
        .p-card--hover .c-link-arrow-longarrow::before {
          //width: calc(100% - 0px);
          width: 100%;
        }
      }
    }
  }

  .c-icon {
    padding-left: 67px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      width: 42px;
      height: 42px;
      display: block;
      left: 0;

      @include centering(transform-y);
      @include transition();
    }

    &--by-bicycle-white {
      &::before {
        background: url("../images/common/c-icon-by-bicycle-w.png");
        background-size: cover;
      }
    }

    &--by-bus-white {
      &::before {
        background: url("../images/common/c-icon-by-bus-w.png");
        background-size: cover;
      }
    }

    &--by-car-white {
      &::before {
        background: url("../images/common/c-icon-by-car-w.png");
        background-size: cover;
      }
    }

    &--by-train-white {
      &::before {
        background: url("../images/common/c-icon-by-train-w.png");
        background-size: cover;
      }
    }

    &--by-walk-white {
      &::before {
        background: url("../images/common/c-icon-by-walk-w.png");
        background-size: cover;
      }
    }

    //&--border {
    //  &::after {
    //    content: "";
    //    height: 3px;
    //    width: calc(100% - 2rem);
    //    margin-left: 2rem;
    //    background: white;
    //    @include centering(transform-y);
    //  }
    //}
  }

  &__swiper-img-wrap {
    height: 270px;
  }

  &__swiper-inner-img-wrap {
    height: 217px;
  }
}
