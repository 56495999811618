
// [note] We can not use BEM in Wordpress autmatic pagenation
.cmn-pagenation {
  .screen-reader-text { // [note] 非表示に
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
  }

  .nav-links {
    width: 100%;
    display: block;
    display: flex;
    justify-content: center;
  }

  .page-numbers {
    $_color: colors("black");
    $_bg-color: colors("white");

    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-weight: 600;
    text-decoration: none !important;
    border-radius: 50%;
    background: $_bg-color;
    color: $_color;
    font-size: 14px;
    margin: 0 5px;

    @include media-breakpoint-down(sm) {
      width: 36px;
      height: 36px;
      margin: 0 3px;
      font-size: 12px;
    }

    &:hover {
      background: $_color;
      color: $_bg-color;
    }

    @mixin prev_next_style {
      position: relative;
      overflow: hidden;
      text-indent: 9999em;
      white-space: nowrap;
    }

    @mixin prev_next_before_style {
      content: "";
      background: url("../images/common/c-icon-arrow.svg");
      background-size: cover;
      display: block;
      width: 10px;
      height: 8px;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &.prev {
      @include prev_next_style;

      margin: 0 20px 0 0;

      @include media-breakpoint-down(sm) {
        margin: 0 10px 0 0;
      }

      &::before {
        @include prev_next_before_style;

        transform: translateY(-50%) translateX(-50%) rotate(90deg);
      }
    }

    &.next {
      @include prev_next_style;

      margin: 0 0 0 20px;

      @include media-breakpoint-down(sm) {
        margin: 0 10px 0 0;
      }

      &::before {
        @include prev_next_before_style;

        transform: translateY(-50%) translateX(-50%) rotate(-90deg);
      }
    }

    &.current {
      background: $_color;
      color: $_bg-color;

      &:hover {
        background: $_color;
        color: $_bg-color;
      }
    }

    &.dots {
      background-color: $_bg-color;
      color: #999;
    }
  }
}

.page-numbers.next:hover,
.page-numbers.prev:hover {
  &::before {
    background: url("../images/common/c-icon-arrow-w.svg");
    background-size: cover;
  }
}
