.swiper-ancestor {
  position: relative;

  .swiper__column-small {
    overflow: hidden;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-color: colors("white");
    background-size: 10px 8px;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(../images/common/c-icon-arrow.svg);
    left: 15px !important;
    transform: rotate(90deg);
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(../images/common/c-icon-arrow.svg);
    right: 15px !important;
    transform: rotate(-90deg);
  }
}

.swiper {
  position: relative;

  .swiper-pagination {
    &.swiper-pagination-fraction {
      position: absolute;
      right: 0;
      top: -5rem;
      bottom: unset;
      left: unset;
      width: max-content;
    }

    &.swiper-pagination-bullets {
      @include centering();
      //padding-top: 20rem;
      padding-top: 15%;

      .swiper-pagination-bullet {
        width: 50px;
        height: 3px;
        border-radius: 0;
        background: colors("white");
        opacity: 1;
        margin: 0 0.25rem;

        &.swiper-pagination-bullet-active {
          background: colors("black");
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-color: colors("white");
    background-size: 10px 8px;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;

    &.swiper-button-style {
      background-color: colors("black");
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(../images/common/c-icon-arrow.svg);
    left: -15px;
    transform: rotate(90deg);
  }

  .swiper-button-prev.swiper-button-style,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(../images/common/c-icon-arrow-w.svg);
    left: -15px;
    transform: rotate(90deg);
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(../images/common/c-icon-arrow.svg);
    right: -15px;
    transform: rotate(-90deg);
  }

  .swiper-button-next.swiper-button-style,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(../images/common/c-icon-arrow-w.svg);
    right: -15px;
    transform: rotate(-90deg);
  }

  // slider
  &__slider {
    width: 100%;
  }

  // column col-4
  &__column {
    width: 1080px;
    margin: auto;
    overflow: visible;

    .swiper-slide {
      opacity: 0.4;

      > div {
        margin: 0 1px;
      }

      &.swiper-slide-active,
      &.swiper-slide-next {
        opacity: 1;
      }

      &.swiper-slide-next {
        + .swiper-slide {
          opacity: 1;
        }
      }
    }
  }

  // column width 869px - blog inner
  &__column-small {
    width: 902px;
    margin: auto;
  }

  // column col-12
  &__column-single {
    .swiper-slide {
      opacity: 0;

      @include transition();

      &.swiper-slide-active {
        opacity: 1;
      }
    }
  }
}


@include media-breakpoint-down(sm) {
  .swiper-ancestor {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 15px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 15px;
    }
  }

  .swiper {
    .swiper-pagination {
      //&.swiper-pagination-fraction {
      //  right: unset;
      //  top: -3rem;
      //  left: 0;
      //}

      &.swiper-pagination-bullets {
        padding-top: 60%;
      }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: -15px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: -15px;
    }

    // column only
    &__column,
    &__column-small {
      width: calc(100% - 5rem);

      .swiper-slide,
      .swiper-slide-prev,
      .swiper-slide-next {
        opacity: 0.4 !important;

        &.swiper-slide-active {
          opacity: 1 !important;
        }
      }
    }
  }
}

_:-ms-fullscreen, :root .swiper .swiper-pagination.swiper-pagination-bullets {
  padding-top: 31rem;
}