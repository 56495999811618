// ========================================
// See & Do
// ========================================
.c-icon--events {
  position: relative;
  padding-left: 50px;

  &::after {
    content: '';
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 50%;
    background: url(../images/common/c-icon-events.svg) no-repeat center center;
    background-size: 37px auto;
    background-color: #fff;
    position: absolute;
    left: -40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include media-breakpoint-down(sm) {
  .c-icon--events {
    position: relative;
    padding-left: 50px;
    padding-bottom: 1.5rem !important;

    &::after {
      content: '';
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 50%;
      background: url(../images/common/c-icon-events.svg) no-repeat center center;
      background-size: 19px auto;
      background-color: #fff;
      position: absolute;
      left: 0px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.see-do {
  color: colors("white");
  position: relative;

  // メニュー用 ボーダーのスタイル
  @include media-breakpoint-up(md) {
    .see-do-menu {
      &__border {
        border-width: 3px;
        border-top: solid;
        border-bottom: solid;
        border-left: solid;

        &:nth-last-child(1) {
          border-right: solid;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .p-tab {
      width: calc(100% / 3);
    }

    .see-do-menu {
      &__borders {
        border-top: solid;
        border-left: solid;
      }

      &__border {
        border-width: 5px;
        border-right: solid;
        border-bottom: solid;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .p-tab {
      width: calc(100% / 2);
    }
  }

  .btn.share {
    width: 38px;
    height: 38px;
  }

  @include media-breakpoint-up(md) {
    .airbnb-embed-frame {
      width: 100%;
    }
  }

  //Wordpress記事入力用支援CSS
  &__inner {
    color: colors("white");

    h1, h2, h3, h4, h5, h6 {
      font-family: 'Frank Ruhl Libre';
      font-weight: 700 !important;
    }

    .aligncenter {
      text-align: center;
    }

    .alignright {
      text-align: right;
    }

    .alignleft {
      text-align: left;
    }

    p{
      @include font-size($lead-font-size);
    }

    hr.wp-block-separator{
      border-top: 3px solid color("white");
      margin: 7rem 0;
    }

    pre {
      color: colors("white");
      max-height: auto;
      margin: 0;
      padding: 3rem;

      @include font-size($lead-font-size);
    }

    img {
      height: auto;
    }
  }

  &__swiper-img-wrap {
    height: 480px;
  }

  &__swiper-inner-img-wrap {
    height: 385px;
  }

  &__swiper-side-img-wrap {
    height: 343px;
  }
}

.see-do-control {
  .c-link-color-box {
    @include media-breakpoint-up(md) {
      &:hover {
        .c-link-arrow-adjacent--right::before {
          width: 120px;
          right: -120px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &:active {
        .c-link-arrow-adjacent--right::before {
          width: 120px;
          right: -120px;
        }
      }
    }
  }
}