.add-spot-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1.3;
  font-size: 1.6rem;
  z-index: 9999;
  font-family: Arial, sans-serif;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }

  // [note] block modifire
  &.hidden {
    display: none;
  }

  // &__dialog-wrap {}

  &__dialog-inner {
    position: fixed;
    left: 0;
    right: 0;
    top: 250px;
    margin: auto;
    width: 90%;
    max-width: 818px;
    border: 1px solid #fff;
    background-color: #fff;
    color: #181818;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.8);
    z-index: 2;
  }

  &__ttl {
    padding: 14px 15px;
    background: #181818;
    color: #fff;

    // [note] Clearfix
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

  &__ttl-close-link {
    float: right;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  &__cnt {
    text-align: center;
    font-size: 1.4rem;
    padding: 34px 10px 47px;
  }

  // &__description {}

  &__btns {
    margin: 0;
    padding: 0;
  }

  &__btn {
    vertical-align: top;
    display: inline-block;
    padding: 13px 4px 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__btn-link {
    display: inline-block;
    padding: 6px;
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    line-height: 1.6;

    &:hover {
      text-decoration: none;
    }

    // [note] style modifire
    &.btn_ok {
      width: 87px;
      color: #181818;
      background-color: #fff;
    }

    &.btn_go {
      width: 318px;
      color: #fff;
      background-color: #1b449c;

      &:hover {
        background-color: darken(#1b449c, 10%);
      }
    }
  }
}
