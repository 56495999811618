.places-nearby {
  padding-bottom: 14rem;

  .c-places-nearby-text {
    margin-bottom: 0;
    z-index: 1;
    > p {
      margin: 0;
    }
  }

  .c-link-color-box__white--black:hover {
    .c-places-nearby-text {
      //background: color("black");
    }
  }

  .c-places-nearby-line {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 0;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      display: block;border-top: 1px solid color("alpha-black-30");
      margin-top: -1px;
      position: absolute;
      left: 0;
      top: 50%;
    }
  }

  .c-link-color-box__white--black:hover {
    .c-places-nearby-line::after {
      background: color("alpha-white-30");
    }
  }
  .c-icon {
      //padding: 0 0 0 46px;
      margin: 0 0 10px 46px;
      position: relative;
      width: calc(100% - 46px);

      &::before {
        content: "";
        width: 30px;
        height: 30px;
        display: block;
        left: -46px;

        @include centering(transform-y);
        @include transition();
      }

      &--by-bicycle {
        &::before {
          background: url("../images/common/c-icon-by-bicycle-b.png");
          background-size: cover;
        }
      }

      &--by-bus {
        &::before {
          background: url("../images/common/c-icon-by-bus-b.png");
          background-size: cover;
        }
      }

      &--by-car {
        &::before {
          background: url("../images/common/c-icon-by-car-b.png");
          background-size: cover;
        }
      }

      &--by-train {
        &::before {
          background: url("../images/common/c-icon-by-train-b.png");
          background-size: cover;
        }
      }

      &--by-walk {
        &::before {
          background: url("../images/common/c-icon-by-walk-b.png");
          background-size: cover;
        }
      }
  }
}

@include media-breakpoint-down(sm) {
  .home-places-nearby {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .c-icon {
      margin: 0 0 0 25px;
      width: calc(100% - 25px);

      &::before {
        width: 15px;
        height: 15px;
        left: -25px;
      }
    }
  }
}